import styled from 'styled-components';
import { ProduceIcon } from '../common/icons';
import { useRef, useState } from 'react';
import { produceClips, supportedPresets } from '../../services/AIClipProducer';
import { videoCreator } from '../../stores/VideoCreatorStore';
import SpinningLoading from '../SpinningLoading';
import AIClipProducerResults from './AIClipProducerResults';
import Modal from '../common/Modal';
import SocialClipButtonList from '../common/SocialClipButtonList';

const AIClipProducer = () => {
  const [selectedPresets, setSelectedPresets] = useState<string[]>([]);
  const [selectedSocials, setSelectedSocials] = useState<string[]>([]);
  const [isProducing, setIsProducing] = useState(false);
  const [producedClips, setProducedClips] = useState<any[] | null>(null);
  const producerAbortController = useRef<{
    id: string;
    aborted: boolean;
  } | null>(null);
  const [showModal, setShowModal] = useState(false);

  const INPUT_PLACEHOLDER = `Customize (Type in what you'd like to see)`;
  const MAX_RETRIES = 3;

  const [presetInputValue, setPresetInputValue] = useState<string>('');

  const [retryCount, setRetryCount] = useState<number>(0);
  const retryCountRef = useRef(retryCount);
  retryCountRef.current = retryCount;

  const onPresetClick = (preset: string) => {
    if (selectedPresets.includes(preset)) {
      setSelectedPresets((prev) => prev.filter((p) => p !== preset));
    } else {
      setSelectedPresets((prev) => [preset]); //  [...prev, preset]);
      setPresetInputValue('');
    }
  };

  const closeProducingModal = () => {
    if (producerAbortController.current && isProducing) {
      producerAbortController.current.aborted = true;
    }
    setProducedClips(null);
    setIsProducing(false);
    setShowModal(false);
  };

  const isProducingDisabled = () => {
    return (
      !(selectedPresets.length || presetInputValue) ||
      !selectedSocials.length ||
      isProducing ||
      !videoCreator.story?.originalVideo
    );
  };

  const onProduceButtonClick = async () => {
    if (
      !(selectedPresets.length || presetInputValue) ||
      !selectedSocials.length
    ) {
      return;
    }
    setIsProducing(true);
    setShowModal(true);
    producerAbortController.current = {
      id: Date.now().toString(),
      aborted: false,
    }; // new AbortController();
    while (retryCountRef.current < MAX_RETRIES) {
      try {
        const matchedParts = await produceClips({
          originalVideo:
            videoCreator.currentVideo?.videoFilePrimary ||
            videoCreator.story!.originalVideo!,
          transcriptionText:
            videoCreator.videoTranscriptionProcessor.getFinalTranscriptionText(),
          transcriptionElements:
            (videoCreator.currentVideo?.videoFilePrimary
              ? videoCreator.finalTranscriptionElements
              : videoCreator.originalTranscription?.elements) || [],
          originalDuration: videoCreator.duration,
          theme: selectedPresets[0] || presetInputValue,
          platform: selectedSocials[0],
          onClipsProduced: (clips) => {
            setProducedClips(clips);
          },
          abortToken: producerAbortController.current,
        });
        console.log('ALL PRODUCED CLIPS', matchedParts);
        if (!matchedParts?.length) {
          setRetryCount((prev) => prev + 1);
        } else {
          setProducedClips(matchedParts);
          break;
        }
      } catch (err: any) {
        console.error(err);
        if (err.message === 'Aborted') {
          return;
        }
        setRetryCount((prev) => prev + 1);
      }
    }
    producerAbortController.current = null;
    setRetryCount(0);
    setIsProducing(false);
  };

  return (
    <Wrapper>
      {isProducing && (
        // <Modal isOpen={true}>
        // <LoadingModal>
        <div style={{ color: '#17c964' }}>
          <SpinningLoading
            Ico={<ProduceIcon width={'24'} height={'24'} />}
            text={`Generating clips${retryCount > 0 ? `. Retrying (${retryCount})` : ''
              }`}
          />
        </div>
        // </LoadingModal>
        // </Modal>
      )}
      <PresetsList>
        <Heading>Find social clips based on theme</Heading>
        {supportedPresets.map((preset) => (
          <PresetOption
            active={selectedPresets.includes(preset)}
            key={preset}
            onClick={() => onPresetClick(preset)}
          >
            {preset}
          </PresetOption>
        ))}
        <PresetInput
          placeholder={INPUT_PLACEHOLDER}
          value={presetInputValue}
          onChange={(e) => {
            setPresetInputValue(e.target.value);
            setSelectedPresets([]);
          }}
        />
      </PresetsList>

      <div>
        <Heading> Select social platform to optimize clips</Heading>
        <SocialClipButtonList
          setSelectedSocials={setSelectedSocials}
          selectedSocials={selectedSocials}
        />
      </div>

      <ProduceButton
        onClick={onProduceButtonClick}
        disabled={isProducingDisabled()}
      >
        <ProduceIcon /> Produce
      </ProduceButton>

      {showModal && Boolean(producedClips?.length) && (
        <Modal isOpen={true} closeModal={closeProducingModal}>
          <AIClipProducerResults
            onClose={closeProducingModal}
            //  () => {
            //   setProducedClips(null);
            //   setShowModal(false);
            // }}
            fragments={producedClips!}
            isProducing={isProducing}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default AIClipProducer;

const Wrapper = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Heading = styled.div`
  width: 100%;
  color: #aeaeae;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%; /* 15px */
`;

const PresetInput = styled.input`
  border: 1px solid #484848;
  border-radius: 8px;
  padding: 8px;
  background: #03041a;
  color: #9d9797;
  width: 100%;
  font-size: 10px;
  line-height: 150%;
  outline: none;
`;

const LoadingModal = styled.div`
  width: 500px;
  height: 400px;
  color: #17c964;
  background: #03041a;
  border: 1px solid #484848;
  border-radius: 8px;
`;

const SocialButtons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

const SocialButton = styled.div.attrs((props: { active: boolean }) => props)`
  display: flex;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #484848;
  color: #484848;
  cursor: pointer;
  align-items: center;
  width: 38px;
  box-sizing: border-box;
  justify-content: center;

  ${(props) =>
    props.active &&
    `
    color: #030419;
    border-color: #F2D093;
    background-color: #F2D093;
  `}
`;

const ProduceButton = styled.div.attrs((props: { disabled: boolean }) => props)`
  width: 100%;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #17c964;
  box-sizing: border-box;

  color: #03041a;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    color: #030419;
    opacity: 0.6;
  `}
`;

const PresetsList = styled.div`
  width: 100;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const PresetOption = styled.div.attrs((props: { active: boolean }) => props)`
  padding: 8px;
  position: relative;
  border-radius: 8px;
  width: calc(50% - 4px);
  box-sizing: border-box;
  border: 1px solid #484848;
  cursor: pointer;
  color: #484848;
  font-size: 10px;
  font-weight: 500;
  line-height: 150%;

  ${(props) =>
    props.active &&
    `
    color: #F2D093;
  `}

  &:hover {
    color: #f2d093;
  }

  &::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    border: 1px solid #484848;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    ${(props) =>
    props.active &&
    `
      font-size: 9px;
      font-weight: 600;
      text-align: center;
      content: '✓';
      background: #4ad067;
    `}
  }
`;
