import styled, { css } from 'styled-components';

export const LogoArea = styled.div<{ left?: string }>`
  position: absolute;
  height: 50px;
  left: ${(props) => props.left || '0'};
  top: 0;
  background-color: #030419;
  z-index: 2;
  img {
    height: 60%;
    margin-top: 20px;
    margin-left: 38px;
  }
`;

export const Circle = styled.div<{ isSelected?: boolean }>`
  position: absolute;
  top: 6px;
  right: 6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #03041966;
  z-index: 1;
  border: 1px solid #d7d7e1;
  ${(props) =>
    props.isSelected &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      background-color: #4ad067;
    `}
`;

export const ActionsWrapper = styled.div<{
  isVisible: boolean;
}>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 1;
  flex-direction: column;

  background: #03041a;
  border: 1px solid #484848;
  border-radius: 8px;
  font-size: 12px;
`;

export const ActionButton = styled.div`
  cursor: pointer;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;
  span {
    white-space: nowrap;
  }
  .icon {
    margin-left: auto;
    display: flex;
  }
  &:not(:first-child) {
    border-top: 1px solid #d9d9d9;
  }
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  color: #f3e9d7;
  &:hover {
    background-color: #17c964;
    color: #03041a;
  }
`;

export const ShareModalDropdown = styled.div<{
  top: number;
  left?: number;
  isBottom: boolean;
  isLeft?: boolean;
}>`
  position: fixed;
  background-color: #03041a;
  padding: 0;
  border: 1px solid #484848;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  z-index: 1000;
`;

export const ShareModalDropdownItem = styled.button`
  color: #f3e9d7;
  font-size: 12px;
  line-height: 14.52px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 20px 16px 20px 16px;

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #484848;
    padding-bottom: 20px;
  }
`;

export const ShareModalAnimate = styled.div<{ color?: string }>`
  border: 2px solid #484848;
  border-radius: 50%;
  border-top: 2px solid ${props => props.color || '#17c964'};
  border-left: 2px solid ${props => props.color || '#17c964'};
  border-right: 2px solid ${props => props.color || '#17c964'};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spinner 1s linear infinite;

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const numericalInputStyles = `
  &:focus {
   outline: 0;
  }
  border: 1px solid #484848;
  background: transparent;
  color: #f3e9d7;
  height: 32px;
  padding: 0 8px;
  margin: 0;
  width: calc(100% - 16px);
  font-size: 14px;
  line-height: 16.94px;
  font-weight: 400;
`;
