import { useEffect } from 'react';
import { VideoCreator } from '../components/VideoCreator';
import { videoCreator } from '../stores/VideoCreatorStore';

import ContentStudio from '../components/ContentStudio';

import { RenderModalCtx } from 'datocms-plugin-sdk';
import {
  createBrowserRouter,
  RouterProvider,
  useParams,
} from 'react-router-dom';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { useAuth, useClerk, useSession, useUser } from '@clerk/clerk-react';
import { withClerkProvider } from './withClerkProvider';
import RootLayout from './RootLayout';
import Dashboard from '../components/Dashboard/';
import { getCurrentStudio } from '../utility/general';
import { Studio } from '../types.ts/general';
import smartlook from 'smartlook-client';
import BrollPage from '../components/BrollPage/index';

function EditorEntry() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { storyId, videoId, env, hash } = params || {};

  const ctx =
    window.opener?.ctx ||
    JSON.parse(window.sessionStorage.getItem('ctx') || 'null');

  const ldClient = useLDClient();
  const clerk = useClerk();
  const session = useSession();
  const auth = useAuth();
  const user = useUser();

  useEffect(() => {
    try {
      if (ctx) {
        // console.log('ctx', ctx);

        const { currentUserAccessToken, currentRole, currentUser } = ctx;
        window.sessionStorage.setItem(
          'ctx',
          JSON.stringify({
            currentUserAccessToken,
            currentRole,
            currentUser,
          }),
        );

        videoCreator.linkFunctions({
          environment: env,
          currentUserAccessToken: ctx.currentUserAccessToken,
          currentUser,
          currentRole,
        } as RenderModalCtx);
      }
    } catch (err) {
      console.log('window.opener err', err);
    }
  }, [storyId, videoId, env]);

  useEffect(() => {
    if (!ctx && session.isLoaded && session.isSignedIn) {
      videoCreator.linkFunctions({
        environment: env,
        currentUserAccessToken: null, // session.session.lastActiveToken?.getRawString(), //getToken, // process.env REACT_APP_DATOCMS_READ_API_TOKEN!,
        clerkUserSessionToken: session.session.lastActiveToken?.getRawString(),
        currentUser: session.session as any, //todo rfct
        currentRole: null as any,
      } as any);
    }
  }, [session.isLoaded]);

  const ldIdentifyClerkUser = () => {
    const { id, emailAddresses } = user?.user || {};
    const ldContext = {
      kind: 'user',
      key: `user-content-studio-${id}-${storyId}`,
      id,
      emailAddress: {
        email: emailAddresses && emailAddresses[0].emailAddress,
        linkedTo: emailAddresses && emailAddresses[0].linkedTo,
      },
      datoRole: 'viewer',
      role: auth.orgRole,
      showcase: auth.orgSlug,
      storyId: storyId,
    };
    // console.log('ldContext', ldContext);
    ldClient!.identify(ldContext);
  };

  const ldIdentifyDatoUser = () => {
    const { currentRole, currentUser } = ctx;

    ldClient!.identify({
      kind: 'user',
      key: `dato-user-${currentUser.id}-${storyId}`,
      id: currentUser.id,
      datoRole: currentRole?.attributes.name,
    });
  };

  useEffect(() => {
    if (ctx && ldClient) {
      ldIdentifyDatoUser();
    } else if (!ctx && session.isLoaded && session.isSignedIn && ldClient) {
      ldIdentifyClerkUser();
    }
  }, [session.isLoaded, ldClient]);

  const currentStudio = getCurrentStudio(window.location.pathname);
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      smartlook.init(
        process.env.REACT_APP_SMARTLOOK_CONTENT_STUDIO_PROJECT_KEY as string,
      );

      if (user?.user) {
        const name = user.user.fullName;
        smartlook.identify(user.user.id, {
          email: user.user.emailAddresses[0]?.emailAddress,
          ...(name && { name }),
          dato: !!ctx,
        });
      } else if (ctx) {
        const { currentUser } = ctx;
        const name = currentUser.attributes?.full_name;
        const email = currentUser.attributes?.email;
        smartlook.identify(currentUser.id, {
          email,
          name,
          dato: true,
        });
      }
    }
  }, [currentStudio, user]);
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <RootLayout>
          <VideoCreator />
        </RootLayout>
      ),
    },
    {
      path: '/creator',
      element: (
        <RootLayout>
          <VideoCreator />
        </RootLayout>
      ),
    },
    {
      path: '/content',
      element: (
        <RootLayout>
          <ContentStudio params={params} />
        </RootLayout>
      ),
    },
    {
      path: '/story-manager',
      element: (
        <RootLayout>
          <Dashboard params={params} />
        </RootLayout>
      ),
    },
    {
      path: '/broll',
      element: <BrollPage />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default withClerkProvider(
  withLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID as string,
    options: {
      useReport: true,
      streaming: false,
    },
  })(EditorEntry),
);
