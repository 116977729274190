import styled, { css } from 'styled-components';
import { Studio } from '../types.ts/general';
import { NavLink, useLocation } from 'react-router-dom';
import { VideoSelector } from './stage/VideoSelector';
import { CreateButton } from './stage/CreateButton';
import { SaveButton } from './stage/SaveButton';
import { observer } from 'mobx-react-lite';
import { videoCreator } from '../stores/VideoCreatorStore';
import { useUser } from '@clerk/clerk-react';
import { useFlagsCombination } from '../utility/useFlagsCombination';
import { getCurrentStudio } from '../utility/general';
import OrganizationAvatar, {
  OrganizationAvatarWrapper,
} from './OrganizationAvatar';

type MenuProps = {
  params: {
    [k: string]: string;
  };
};

const StudioNames = {
  [Studio.creator]: 'Creator Studio',
  [Studio.content]: 'Content Studio',
  [Studio.dashboard]: 'Story Manager',
};

const MainMenu = observer((props: MenuProps) => {
  const { pathname, search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  const { enableCreatorStudioNav, hideStoryDashboardNav } =
    useFlagsCombination();
  const album = videoCreator.organization;
  const userInfo = useUser();

  const currentStudio = getCurrentStudio(pathname);

  const shouldShowNavItem = (studio: Studio) => {
    if ((hideStoryDashboardNav || !album) && studio === Studio.dashboard) {
      return false;
    }
    if (studio === Studio.creator && !enableCreatorStudioNav) {
      return false;
    }
    return true;
  };

  const renderNavItems = (value: Studio) => {
    let link = `/${value}${window.location.search}`;
    // if (value === Studio.storyDashboard) {
    //   link = `${process.env.REACT_APP_API_URL}/showcase/${album?.slug}`;
    // }
    // if (value === Studio.dashboard) {
    //   link = `/${value}?env=${urlSearchParams.get('env')}`;
    // }

    return (
      <NavItem key={value} to={link} isSelected={currentStudio === value}>
        {StudioNames[value]}
      </NavItem>
    );
  };

  if (!currentStudio) return null;

  return (
    <Main currentStudio={currentStudio!}>
      <Content>
        <LeftContent>
          <Nav>
            <ArborLogo currentStudio={currentStudio}>
              <img src="/logo/arbor_logo.png" />
            </ArborLogo>
            <NavItems currentStudio={currentStudio}>
              {Object.values(Studio)
                .filter(shouldShowNavItem)
                .map(renderNavItems)}
            </NavItems>
          </Nav>
          {currentStudio === Studio.creator && <VideoSelector />}
        </LeftContent>

        {currentStudio === Studio.creator && (
          <RightContent>
            <CreateButton />
            <SaveButton />
          </RightContent>
        )}

        {(currentStudio === Studio.content ||
          currentStudio === Studio.dashboard) &&
          album && (
            <RightContent>
              <OrganizationAvatar
                album={album}
                username={userInfo.user?.fullName}
              />
            </RightContent>
          )}
      </Content>
    </Main>
  );
});

export default MainMenu;
const Main = styled.nav<{ currentStudio: Studio }>`
  position: relative;
  padding-left: 39px;
  display: flex;
  gap: 40px;
  z-index: 2;
  justify-content: flex-start;
  height: 68px;
  border-bottom: 1px solid #333;
  align-items: center;
`;

const Content = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: flex-end;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Nav = styled.div`
  display: flex;
  gap: 40px;
  justify-content: flex-start;
  align-items: flex-end;
  width: fit-content;
  height: fit-content;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
`;

const RightContent = styled(OrganizationAvatarWrapper)`
  margin-right: 25px;
`;

const NavItems = styled.nav<{ currentStudio: Studio }>`
  padding: 0;
  display: flex;
  gap: 20px;
  align-items: center;
  ${(props) =>
    props.currentStudio === Studio.creator &&
    css`
      padding-right: 10px;
    `}
`;

const NavItem = styled(NavLink)<{ isSelected: boolean }>`
  padding: 0;
  font-size: 12px;
  cursor: pointer;
  color: #f3e9d7;
  display: flex;
  border: none !important;
  text-decoration: none;

  ${(props) =>
    props.isSelected &&
    css`
      color: #f2d093;
      text-decoration: underline;
    `}
`;

export const ArborLogo = styled.div<{ currentStudio: Studio }>`
  position: relative;
  left: unset;
  right: unset;
  height: 26px;
  margin-left: 0;
  margin-top: 0;
  z-index: 1;
  img {
    height: 80%;
  }
`;
