import {
  FC,
  FocusEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import SearchIcon from '../../svgs/SearchIcon';
import RemoveIconAlt from '../../svgs/RemoveIconAlt';
import lodash from 'lodash';
import AutoResizeableMultilineInput from '../common/AutoResizeableMultilineInput';

type Props = {
  placeholder?: string;
  iconRight?: boolean;
  handleAction: (textValue: string) => void;
  hideClearAll?: boolean;
  initialValue?: string;
  radius?: string;
  expandHeight?: boolean;
  hideIcons?: boolean;
  width?: string;
  autoSubmitDelay?: number;
};
const SearchInput: FC<Props> = (props) => {
  const {
    hideClearAll = false,
    initialValue = '',
    radius = '100px',
    expandHeight = false,
    width,
    autoSubmitDelay,
  } = props;
  const [textValue, setTextValue] = useState<string>(initialValue);
  const [inputHeight, setInputHeight] = useState<number>(40);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.handleAction(textValue);
  };

  const debouncedSubmit = useCallback(
    lodash.debounce(
      (textValue: string) => props.handleAction(textValue),
      autoSubmitDelay,
    ),
    [props.handleAction],
  );

  const onTextChange = (textValue: string) => {
    setTextValue(textValue);
    if (autoSubmitDelay) {
      debouncedSubmit(textValue);
    }
  };

  const handleAdjustHeight = (textarea: HTMLTextAreaElement) => {
    if (textarea.scrollHeight < 30) return;
    setInputHeight(textarea.scrollHeight);
    textarea.style.height = textarea.scrollHeight + 'px';
  };

  const handleAdjustInputHeight = (e: FormEvent<HTMLTextAreaElement>) => {
    const textarea = e.target as HTMLTextAreaElement;
    handleAdjustHeight(textarea);
  };

  const handleAdjustHeightOnFocus = (
    e: FocusEvent<HTMLTextAreaElement, Element>,
  ) => {
    const textarea = e.target as HTMLTextAreaElement;
    handleAdjustHeight(textarea);
  };

  const handleAdjustHeightOnBlur = (
    e: FocusEvent<HTMLTextAreaElement, Element>,
  ) => {
    const textarea = e.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    let newHeight = 35;
    setInputHeight(newHeight);
    textarea.style.height = newHeight + 'px';
  };

  const lineHeight = inputHeight - 10;
  return (
    <Form
      iconRight={props.iconRight}
      radius={radius}
      expandHeight={expandHeight}
      onSubmit={handleSubmit}
      width={width}
    >
      {!props.hideIcons && (
        <Search>
          {props.iconRight ? (
            <>
              {textValue?.length && !hideClearAll ? (
                <>
                  <div className="clear-all" onClick={() => onTextChange('')}>
                    <RemoveIconAlt />
                  </div>
                </>
              ) : null}
              <svg
                width="2"
                height={lineHeight}
                viewBox={`0 0 2 ${lineHeight}`}
                fill="none"
              >
                <path d={`M1.00195 0V${lineHeight}`} stroke="#484848" />
              </svg>
            </>
          ) : null}

          <div className="search">
            <SearchIcon fillColor="#F2D093" />
          </div>
        </Search>
      )}
      {expandHeight ? (
        <AutoResizeableMultilineInput
          hasOutline={false}
          hasBorder={false}
          borderOnFocus={false}
          placeholder={props.placeholder || 'Describe your voice'}
          width="100%"
          customHeight="20px"
          getValue={(value: string) => {
            onTextChange(value);
            if (value === '') {
              props.handleAction(value);
            }
          }}
          handleOnEnter={() => props.handleAction(textValue)}
        />
      ) : (
        <Input
          type="text"
          value={textValue}
          onChange={(e) => {
            e.stopPropagation();
            onTextChange(e.target.value);
            if (e.target.value === '') {
              props.handleAction(e.target.value);
            }
          }}
          placeholder={props.placeholder || 'Search'}
        />
      )}
    </Form>
  );
};

export default SearchInput;

const Form = styled.form<{
  iconRight?: boolean;
  radius: string;
  expandHeight?: boolean;
  width?: string | undefined;
}>`
  display: flex;
  height: fit-content;
  padding: 0 15px;
  align-items: center;
  gap: 10px;
  border-radius: ${(props) => props.radius};
  border: 1px solid #333;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${(props) =>
    props.iconRight &&
    css`
      flex-direction: row-reverse;
    `}
  ${(props) =>
    props.expandHeight &&
    css`
      padding: 8px 15px;
    `}
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .search {
    rotate: -10deg;
  }
  .search,
  .clear-all {
    margin-top: 5px;
  }
`;

const Input = styled.input`
  color: #ddd;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  border: none;
  outline: none;
  background-color: #030419;
  flex: 1;
  min-width: 20px;
  height: 40px;
`;

const TextArea = styled.textarea<{ inputHeight: number }>`
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  display: flex;
  gap: 16px;
  border: none;
  text-align: left;
  font-size: 12px;
  background-color: transparent;
  color: #ddd;
  width: 100%;
  resize: none;
  height: 30px;
  min-height: 30px;
  transition: height 0.5s ease;
  overflow: auto;
  overflow-y: hidden;
  white-space: ${(props) => (props.inputHeight <= 30 ? 'nowrap' : 'wrap')};
  outline: 0;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:focus {
    border: none;
    outline: 0;
    white-space: wrap;
    // overflow: auto;
  }
`;
