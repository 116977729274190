import React, { Fragment, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS, runInAction } from 'mobx';
import styled from 'styled-components';
import { ElementState } from '../../renderer/ElementState';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { Draggable } from '../Draggable';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import { ExtraElementData, VolumeKeyPoint } from '../../types.ts/story';

interface VolumeKeyPointsProps {
  id: string;
  height: number;
  type: string;
  element: ElementState;
}
type KeypointToolTip = {
  pos: DOMRect;
  data: Record<'time' | 'value', string>;
} | null;

const CONTAINER_PADDING_AND_BORDER_WIDTH = 0;

export const VolumeKeyPoints: React.FC<VolumeKeyPointsProps> = observer(
  (props) => {
    const [tooltip, setTooltip] = useState<KeypointToolTip | null>(null);
    const [isDotDragging, setIsDotDragging] = useState<boolean>(false);
    const [isDragEnabled, setIsDragEnabled] = useState<boolean>(false);
    const active = videoCreator.activeElementIds.includes(props.id);
    const volumeKeyPoints = (
      videoCreator.currentVideo?.extraElementData[
        props.element.source?.id
      ] as ExtraElementData
    )?.volumeKeyPoints;

    // console.log('volumeKeyPoints top', volumeKeyPoints)
    const timelineScale = videoCreator.timelineScale;

    const [volume, setVolume] = useState(
      props.element?.source?.volume || '100 %',
    );
    const [volumeKeyPointsLocal, setVolumeKeyPointsLocal] = useState(
      toJS(volumeKeyPoints),
    );
    const svgContainerRef = useRef<SVGSVGElement | null>(null);
    const audioSettingsRef = useRef<Element | null>(null);

    useEffect(() => {
      setVolume(props.element?.source?.volume || '100 %');
    }, [props.element?.source?.volume]);

    useEffect(() => {
      setVolumeKeyPointsLocal(toJS(volumeKeyPoints));
    }, [
      volumeKeyPoints,
      (volumeKeyPoints || []).map((p) => `${p.time}_${p.value}`).join('-'),
    ]);

    useEffect(() => {
      if (active) {
        audioSettingsRef.current = document.getElementById(
          'audio-settings-wrapper',
        );
      } else {
        audioSettingsRef.current = null;
      }
    }, [active]);

    const applyVolume = async () => {
      //todo move to state, push to undo/redo
      // console.log('applying volume', props.id, volume);
      await videoCreator.renderer?.applyModifications({
        [`${props.id}.volume`]: volume,
      });
    };

    const applyVolumeKeyPoints = async (newVolumeKeyPoints: any) => {
      if (newVolumeKeyPoints) {
        videoCreator.applyVolumeKeyPoints(props.id, newVolumeKeyPoints);
      }
    };

    const getPaddingTop = (): number => {
      return props.type === 'audio' ? 17 : 4;
    };

    const getPaddingBot = (): number => {
      return 13;
    };

    const scaleVolumeY = (volume: string) => {
      if (volume) {
        const volumeValue = parseFloat(volume.replace('%', ''));
        const pBot = getPaddingBot();
        const pTop = getPaddingTop();
        return ((props.height - (pBot + pTop)) * volumeValue) / 100 + pBot;
      } else {
        return props.height / 2;
      }
    };

    const calculateVolume = (y: number): number => {
      const pBot = getPaddingBot();
      const pTop = getPaddingTop();
      return Math.max(
        0,
        Math.min(
          100,
          ((props.height - y - pBot) * 100) / (props.height - (pBot + pTop)),
        ),
      );
    };

    const calculateClosestLinePoint = (
      event: React.MouseEvent<SVGRectElement, MouseEvent>,
      lineStartX: number,
      lineStartY: number,
      lineEndX: number,
      lineEndY: number,
    ): [number, number] => {
      const svg = svgContainerRef.current!;
      const point = svg.createSVGPoint();
      point.x = event.clientX;
      point.y = event.clientY;
      const svgPoint = point.matrixTransform(svg.getScreenCTM()?.inverse());
      const clickX = svgPoint.x;
      const clickY = svgPoint.y;
      // Calculate the closest point on the line
      const dx = lineEndX - lineStartX;
      const dy = lineEndY - lineStartY;
      const t =
        ((clickX - lineStartX) * dx + (clickY - lineStartY) * dy) /
        (dx * dx + dy * dy);
      const closestX = lineStartX + t * dx;
      const closestY = lineStartY + t * dy;
      return [closestX, closestY];
    };

    const showTooltipForLinePoint = (
      event: React.MouseEvent<SVGRectElement, MouseEvent>,
      x: number,
      y: number,
    ) => {
      const value = calculateVolume(y);
      const time = x / timelineScale;
      const volumeKeyPoint = {
        time: `${time.toString()} s`,
        value: `${value.toString()} %`,
      };
      setTooltip({
        pos: {
          ...event.currentTarget.getBoundingClientRect(),
          left: event.clientX - 7, // 7 = dot radius
          top: event.clientY - 7,
        },
        data: volumeKeyPoint,
      });
    };

    const addNewKeyPoint = async (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      const x = e.clientX - e.currentTarget.getBoundingClientRect().left;
      const y = e.clientY - e.currentTarget.getBoundingClientRect().top;
      await doAddNewKeyPoint(x, y);
    };

    const doAddNewKeyPoint = async (x: number, y: number) => {
      const time = x / timelineScale;
      const value = calculateVolume(y);
      const newKeyPoint = {
        time: `${time.toString()} s`,
        value: `${value.toString()} %`,
      };
      let newVolumeKeyPoints: VolumeKeyPoint[] = [];
      if (!volumeKeyPointsLocal?.length) {
        const startKeyPoint = {
          time: '0 s',
          value: props.element?.source?.volume || '100 %',
        };
        const endKeyPoint = {
          time:
            props.element?.source?.duration || `${props.element.duration} s`,
          value: props.element?.source?.volume || '100 %',
        };
        newVolumeKeyPoints = [startKeyPoint, newKeyPoint, endKeyPoint];
      } else {
        newVolumeKeyPoints = [...volumeKeyPointsLocal, newKeyPoint].sort(
          (a, b) => parseFloat(a.time) - parseFloat(b.time),
        );
      }

      runInAction(() => {
        setVolumeKeyPointsLocal(newVolumeKeyPoints);
        applyVolumeKeyPoints(newVolumeKeyPoints);
      });
    };

    const keyPointController = (index: number, x: number, y: number) => {
      return (
        <Draggable
          key={`point-${index}`}
          onStart={(e, data) => {
            setIsDotDragging(true);
            return { startY: data.y, startX: data.x };
          }}
          onDrag={(e, data, context) => {
            const volumeMovement = context.startY - data.y;
            const timeMovement = data.x - context.startX;
            const timeOffset = timeMovement / timelineScale;
            const volumeOffset = (volumeMovement * 100) / props.height;
            if (volumeKeyPoints && volumeKeyPoints?.length > 0) {
              const origVolume = parseFloat(volumeKeyPoints[index].value);
              const origTime = parseFloat(volumeKeyPoints[index].time);
              // console.log('orig volume', origVolume, 'orig time', origTime, 'volume offset', volumeOffset, 'time offset', timeOffset)
              runInAction(() => {
                // otherwise dot will lose selected state on drag
                const shouldUpdateSelectedVolumeKeyPoint =
                  videoCreator.isSelectedVolumeKeyPoint(
                    volumeKeyPointsLocal![index],
                  );
                // only move time if not first or last key point
                if (index !== 0 && index !== volumeKeyPoints.length - 1) {
                  const minTime = parseFloat(volumeKeyPoints[index - 1].time);
                  const maxTime = parseFloat(volumeKeyPoints[index + 1].time);
                  volumeKeyPointsLocal![index].time = `${Math.min(
                    maxTime,
                    Math.max(origTime + timeOffset, minTime),
                  )} s`;
                }
                volumeKeyPointsLocal![index].value = `${Math.min(
                  100,
                  Math.max(origVolume + volumeOffset, 0),
                )} %`;
                setVolumeKeyPointsLocal([...volumeKeyPointsLocal!]);
                if (shouldUpdateSelectedVolumeKeyPoint) {
                  videoCreator.selectVolumeKeyPoint(
                    volumeKeyPointsLocal![index],
                  );
                }
                setTooltip({
                  pos: data.node.getBoundingClientRect(),
                  data: volumeKeyPointsLocal![index],
                });
              });
            }
          }}
          onStop={() => {
            applyVolumeKeyPoints(volumeKeyPointsLocal);
            setIsDotDragging(false);
          }}
        >
          {(ref) => (
            <g ref={ref}>
              <Dot
                x={x}
                y={y}
                active={active}
                volumeKeyPoint={volumeKeyPointsLocal![index]}
                canSelect={
                  index !== 0 && index !== volumeKeyPointsLocal!.length - 1
                }
                element={props.element}
                setTooltip={setTooltip}
                isDragging={isDotDragging}
                clickOutsideExceptionElementRef={audioSettingsRef}
              />
            </g>
          )}
        </Draggable>
      );
    };

    if (volumeKeyPointsLocal && volumeKeyPointsLocal.length) {
      const elementDuration =
        parseFloat(props.element?.source?.duration) || props.element?.duration;
      const svgWidth =
        elementDuration * timelineScale - CONTAINER_PADDING_AND_BORDER_WIDTH;
      // key points
      const toolTipFormatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: 1,
        minimumFractionDigits: 0,
      });

      return (
        <Fragment>
          <Container onDoubleClick={addNewKeyPoint}>
            <svg
              width={svgWidth}
              height={props.height}
              xmlns="http://www.w3.org/2000/svg"
              ref={svgContainerRef}
            >
              <g>
                {volumeKeyPointsLocal.map((point, index) => {
                  const y = props.height - scaleVolumeY(point.value); // svg coordinates are y inverted
                  const x =
                    (parseFloat(point.time) * svgWidth) / elementDuration; //  * timelineScale;
                  let prevY =
                    props.height -
                    (index > 0
                      ? scaleVolumeY(volumeKeyPointsLocal[index - 1].value)
                      : 0); // svg coordinates are y inverted
                  let prevX =
                    index > 0
                      ? (parseFloat(volumeKeyPointsLocal[index - 1].time) *
                          svgWidth) /
                        elementDuration
                      : 0;
                  return index > 0 ? (
                    <>
                      <rect
                        key={`rect-${index}`}
                        x={prevX}
                        y={prevY - 6 / 2}
                        width={Math.sqrt((x - prevX) ** 2 + (y - prevY) ** 2)}
                        height={6}
                        transform={`rotate(${
                          (Math.atan2(y - prevY, x - prevX) * 180) / Math.PI
                        } ${prevX} ${prevY})`}
                        fill="transparent"
                        onDoubleClick={(event) => {
                          event.stopPropagation();
                          doAddNewKeyPoint(
                            ...calculateClosestLinePoint(
                              event,
                              prevX,
                              prevY,
                              x,
                              y,
                            ),
                          );
                        }}
                        onMouseEnter={(event) => {
                          showTooltipForLinePoint(
                            event,
                            ...calculateClosestLinePoint(
                              event,
                              prevX,
                              prevY,
                              x,
                              y,
                            ),
                          );
                        }}
                        onMouseMove={(event) => {
                          showTooltipForLinePoint(
                            event,
                            ...calculateClosestLinePoint(
                              event,
                              prevX,
                              prevY,
                              x,
                              y,
                            ),
                          );
                        }}
                        onMouseLeave={() => {
                          setTooltip(null);
                        }}
                      />
                      <line
                        key={`line-${index}`}
                        x1={prevX}
                        y1={prevY}
                        x2={x}
                        y2={y}
                        stroke={active ? 'white' : 'gray'}
                      />
                    </>
                  ) : null;
                })}
                {volumeKeyPointsLocal.map((point, index) => {
                  // separate .map() from <line/> to make sure dot is drawn on top of line
                  const y = props.height - scaleVolumeY(point.value); // svg coordinates are y inverted
                  const currTime = point.time;
                  const maxTime =
                    volumeKeyPointsLocal[volumeKeyPointsLocal.length - 1].time;

                  const currX =
                    (parseFloat(currTime) * svgWidth) / elementDuration;
                  const maxX =
                    (parseFloat(maxTime) * svgWidth) / elementDuration - 7;

                  let x = Math.min(Math.max(6, currX), maxX);

                  return keyPointController(index, x, y);
                })}
              </g>
            </svg>
            {tooltip && (
              <ToolTip top={tooltip.pos.top - 30} left={tooltip.pos.left - 15}>
                {toolTipFormatter.format(parseFloat(tooltip.data.value))}%
              </ToolTip>
            )}
          </Container>
        </Fragment>
      );
    } else {
      // straight line
      return (
        <Fragment>
          <Draggable
            disabled={!isDragEnabled}
            onStart={(e, data) => {
              return { startY: data.y };
            }}
            onDrag={(e, data, context) => {
              const volumeMovement = context.startY - data.y;
              const volumeOffset = (volumeMovement * 100) / props.height;
              let origVolume = 100;
              if (props.element?.source?.volume) {
                origVolume = parseFloat(
                  props.element?.source?.volume.replace('%', '').trim(),
                );
              }
              setVolume(
                `${Math.min(100, Math.max(origVolume + volumeOffset, 0))} %`,
              );
            }}
            onStop={() => {
              applyVolume();
            }}
          >
            {(ref) => (
              <Container
                ref={ref}
                onDoubleClick={(event) => {
                  const line = event.currentTarget.children[0];
                  const lineRect = line.getBoundingClientRect();
                  const clickY = event.clientY;
                  const x =
                    event.clientX -
                    event.currentTarget.getBoundingClientRect().left;

                  // Handle clicks in the 2-pixel area above or below the Line
                  const tresholdTop = lineRect.top - 2;
                  const tresholdBot = lineRect.bottom + 2;
                  if (tresholdTop < clickY && tresholdBot > clickY) {
                    doAddNewKeyPoint(x, props.height - scaleVolumeY(volume));
                    return;
                  }

                  addNewKeyPoint(event);
                }}
              >
                <Line
                  className="volume-key-point"
                  text={parseFloat(volume).toFixed(0) + ' %'}
                  volumeheight={scaleVolumeY(volume)}
                  active={active}
                  onMouseEnter={() => {
                    setIsDragEnabled(true);
                  }}
                  onMouseMove={() => {
                    if (!isDragEnabled) {
                      setIsDragEnabled(true);
                    }
                  }}
                  onMouseLeave={() => {
                    setIsDragEnabled(false);
                  }}
                />
              </Container>
            )}
          </Draggable>
        </Fragment>
      );
    }
  },
);

type DotProps = {
  x: number;
  y: number;
  active: boolean;
  volumeKeyPoint: VolumeKeyPoint;
  canSelect?: boolean;
  element: ElementState;
  setTooltip: React.Dispatch<React.SetStateAction<KeypointToolTip>>;
  isDragging: boolean;
  clickOutsideExceptionElementRef: React.RefObject<Element | null>;
};
const Dot = observer(
  ({
    active,
    volumeKeyPoint,
    x,
    y,
    canSelect,
    element,
    setTooltip,
    isDragging,
    clickOutsideExceptionElementRef,
  }: DotProps) => {
    const dotRef = useRef<SVGCircleElement>(null);
    useOutsideAlerter(dotRef, (event) => {
      if (
        clickOutsideExceptionElementRef.current?.contains(event.target as Node)
      ) {
        return;
      }
      videoCreator.selectVolumeKeyPoint(null);
    });
    const didMouseLeaveWhileDragging = useRef<boolean>(false);

    useEffect(() => {
      if (!isDragging && didMouseLeaveWhileDragging.current) {
        didMouseLeaveWhileDragging.current = false;
        setTooltip(null);
      }
    }, [isDragging]);

    return (
      <>
        <Circle
          className="volume-key-point"
          ref={dotRef}
          cx={x}
          cy={y}
          r="7"
          color={active ? 'white' : 'black'}
          fill={
            videoCreator.isSelectedVolumeKeyPoint(volumeKeyPoint)
              ? '#17c964'
              : active
                ? 'white'
                : 'gray'
          }
          onClick={(event) => {
            const activeElement = videoCreator.getActiveElement();
            if (
              !activeElement ||
              activeElement.source.id !== element.source.id
            ) {
              return; // need to select corresponding active element first
            }
            event.stopPropagation();
            if (canSelect) {
              videoCreator.selectVolumeKeyPoint(volumeKeyPoint);
            }
          }}
          onMouseEnter={(e) => {
            setTooltip({
              pos: e.currentTarget.getBoundingClientRect(),
              data: volumeKeyPoint,
            });
            didMouseLeaveWhileDragging.current = false;
          }}
          onMouseMove={(e) => {
            setTooltip({
              pos: e.currentTarget.getBoundingClientRect(),
              data: volumeKeyPoint,
            });
          }}
          onMouseLeave={() => {
            if (isDragging) {
              didMouseLeaveWhileDragging.current = true;
              return;
            }
            setTooltip(null);
          }}
        />
      </>
    );
  },
);

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: auto;
`;

const Circle = styled.circle`
  pointer-events: auto;
  cursor: resize;
`;

const ToolTip = styled.div<Record<'top' | 'left', number>>`
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #484848;
  color: #f3e9d7;
  font-family: Inter;
  font-size: 8px;
  font-weight: 400;
  z-index: 10000;
  font-size: 16px;
  position: fixed;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
`;

const Line = styled.hr<{
  volumeheight: number;
  text?: string;
  active: boolean;
}>`
  width: 100%;
  position: absolute;
  bottom: ${(props) => props.volumeheight}px;
  margin: 0px;
  pointer-events: auto;
  cursor: ns-resize;
  overflow: visible;
  color: white;
  opacity: ${(props) => (props.active ? 1 : 0.5)};

  ${(props) =>
    props.text &&
    `
      &:after {
        content: '${props.text}';
        position: absolute;
        left: -1px;
        bottom: 0;
        top: 0;
        font-size: 10px;
        color: white;
        white-space: nowrap;
      }
    `}

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 16px;
    top: -8px;
    opacity: 0;
  }
`;
