import { forwardRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  KARAOKE_TRACK_NUMBER,
  videoCreator,
} from '../../stores/VideoCreatorStore';

import AIGenerateCard from '../common/AIGenerateCard';
import SpinningLoading from '../SpinningLoading';
import {
  Action,
  Align,
  AlignValues,
  Bold,
  BoldValues,
  Readability,
  TextSettingsUI,
} from './TextSettingsUI';
import CheckIcon from '../../svgs/CheckIcon';
import CircleCheckIcon from '../../svgs/CircleCheckIcon';
import { observer } from 'mobx-react-lite';
import {
  DEFAULT_KARAOKE_CONFIG,
  KaraokeConfig,
} from '../../videoTranscriptionProcessor/KaraokeProducer';
import SaveOrCancelTemplate from '../common/SaveOrCancelTemplate';
import TextBrandingTemplates from '../common/TextBrandingTemplates';

const AIKaraokeTextProducer = observer(
  forwardRef(({}, ref) => {
    const isKaraokeGenerated = videoCreator.tracks?.has(KARAOKE_TRACK_NUMBER);
    const [loadedWithKaraoke, setLoadedWithKaraoke] =
      useState<boolean>(!!isKaraokeGenerated);
    const defaultKaraokeConfig = {
      ...DEFAULT_KARAOKE_CONFIG,
      ...videoCreator.karaokeProducer.getKaraokeTextSettingByAspectRatio(),
    };

    const [config, setConfig] = useState<KaraokeConfig>(
      videoCreator.karaokeProducer.getKaraokeConfig() || defaultKaraokeConfig,
    );

    const [addNewTemplate, toggleAddNewTemplate] = useState<boolean>(false);
    const [templateName, setTemplateName] = useState<string>();
    const [templateNameError, setTemplateNameError] = useState<boolean>(false);
    const [allowCustomTemplateSave, setAllowCustomTemplateSave] =
      useState<boolean>(false);

    const hasStickyBottom =
      addNewTemplate ||
      allowCustomTemplateSave ||
      videoCreator.textBrandingService.isTemplateModified;

    function updateSelectedActionsForReadability(
      newConfig: any,
      selectedActions: (Action | Readability)[],
    ) {
      for (const action in Readability) {
        if (Object.prototype.hasOwnProperty.call(Readability, action)) {
          const readabilityAction =
            Readability[action as keyof typeof Readability];

          if (
            newConfig[`hide${readabilityAction}` as keyof typeof newConfig] &&
            !selectedActions.includes(readabilityAction)
          ) {
            selectedActions.push(readabilityAction);
          } else if (
            !newConfig[`hide${readabilityAction}` as keyof typeof newConfig] &&
            selectedActions.includes(readabilityAction)
          ) {
            selectedActions = selectedActions.filter(
              (action) => action !== readabilityAction,
            );
          }
        }
      }
    }

    useEffect(() => {
      const newConfig =
        videoCreator.karaokeProducer.getKaraokeConfig() || defaultKaraokeConfig;
      const selectedActions: (Action | Readability)[] = [];

      if (newConfig.font_style === 'italic') {
        selectedActions.push(Action.italic);
      }
      if (newConfig.text_transform === 'uppercase') {
        selectedActions.push(Action.capitalize);
      }

      updateSelectedActionsForReadability(newConfig, selectedActions);
      setConfig(newConfig);
      setSelectedActions(selectedActions);
    }, [videoCreator.karaokeProducer.getKaraokeConfig()]);

    useEffect(() => {
      return () => {
        if (videoCreator.textBrandingService.isTemplateModified) {
          videoCreator.textBrandingService.isTemplateModified = false;
        }
      };
    }, []);

    const [selectedActions, setSelectedActions] = useState<
      (Action | Readability)[]
    >([Action.align]);
    // const [alignType, setAlignType] = useState<Align>(Align.midAlign);
    // const [selectedBold, setSelectedBold] = useState<Bold | null>(Bold.bold);
    const selectedBold = Object.keys(BoldValues).find(
      (k: any) => BoldValues[k as Bold] === Number(config.font_weight),
    ) as Bold;

    const alignType = Object.keys(AlignValues).find(
      (k) => AlignValues[k as Align] === config.x_alignment,
    ) as Align;

    const toggleAction = (action: Action) => {
      const hasAction = selectedActions.find((a) => a === action);

      let updatedActions = [...selectedActions];
      if (hasAction) {
        updatedActions = updatedActions.filter((a) => a !== action);
      } else {
        updatedActions = [...updatedActions, action];
      }
      setSelectedActions(updatedActions);
    };

    const modifyProperty = async (propertyMap: Partial<KaraokeConfig>) => {
      const newConfig = { ...config, ...propertyMap };
      setConfig(newConfig);
      videoCreator.textBrandingService.checkPropsChanged(
        'karaoke',
        propertyMap,
      );

      videoCreator.karaokeProducer.rerenderWithNewConfig(newConfig);
    };

    const setAnimation = (
      time: string | number | null,
      type: any,
      subType: any = null,
      speed: any = null,
      duration: number | null = null,
      background_effect: string | null = null,
    ) => {
      const isInstagram = type === 'instagram';
      // Remove existing animation from list
      const newAnimations =
        config.animations?.filter(
          (keyframe: any) =>
            !(!keyframe.time && !time) && keyframe.time !== time,
        ) ?? [];

      if (type !== 'none') {
        if (isInstagram) {
          type = 'text-appear';
        }
        const animation: any = { type };

        if (!!time) {
          animation.time = time;
          animation.duration = duration || 1;
        }

        if (background_effect) {
          animation.background_effect = background_effect;
        }

        // Reverse animation when used as exit animation
        if (time === 'end') {
          animation.reversed = true;
        }

        newAnimations.push(animation);
      }
      const newConfig: KaraokeConfig = {
        ...config,
        instagramEffect: isInstagram,
        ...(!config.instagramEffect &&
          isInstagram && {
            hideComma: true,
            hidePeriod: true,
          }),
        language: isInstagram ? 'original' : config.language,
        animations: newAnimations,
      };
      // setConfig(newConfig);
      videoCreator.textBrandingService.checkPropsChanged('karaoke', {
        animations: newAnimations,
      });
      videoCreator.karaokeProducer.rerenderWithNewConfig(newConfig);
      // TODO revisit
      // if (!isInstagram && !hasInstagramEffect) {
      //   videoCreator.karaokeProducer!.renderKaraokeElements(newConfig);
      // }
    };

    const handleToggleAction = (
      action: Action,
      propertyName: keyof KaraokeConfig,
      normalState: string,
      requiredState: string,
    ) => {
      // debugger;
      toggleAction(action);
      const isSelected = selectedActions.includes(action);
      const state = isSelected ? normalState : requiredState;
      modifyProperty({ [propertyName]: state });
    };

    const handleSanitizePunctuation = async (action: Readability) => {
      const newConfig = { ...config };
      if (action === Readability.comma) {
        newConfig.hideComma = !config.hideComma;
      }
      if (action === Readability.period) {
        newConfig.hidePeriod = !config.hidePeriod;
      }

      if (action === Readability.filler) {
        newConfig.hideFillers = !config.hideFillers;
      }
      setConfig(newConfig);
      await produceKaraokeText(newConfig);
    };

    const toggleAlign = (align: Align) => {
      modifyProperty({
        x_alignment: AlignValues[align],
        x: '50%', //default position to keep it visible
      });
    };

    const handleToggleBold = (bold: Bold) => {
      const state = bold === selectedBold ? null : bold;
      let value = state ? BoldValues[state] : BoldValues[Bold.normal];
      modifyProperty({ font_weight: value.toString() });
    };

    //todo add config for text elements

    const produceKaraokeText = async (newConfig = config) => {
      // const hideComma = !!config.instagramEffect || !!config.hideComma;
      // const hidePeriod = !!config.instagramEffect || !!config.hidePeriod;
      // const hideFillers = !!config.hideFillers;
      videoCreator.karaokeLoading = true;
      await videoCreator.karaokeProducer!.produceKaraoke(newConfig);
      videoCreator.karaokeLoading = false;
    };

    return (
      <Main>
        {videoCreator.renderer?.ready && (
          <>
            {videoCreator.textBrandingService.isLoading && (
              <SpinningLoading
                positionTop="20px"
                text={
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Saving template</span>
                  </div>
                }
              />
            )}
            {videoCreator.textBrandingService.isPropsChanged && (
              <SpinningLoading
                positionTop="20px"
                text={
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Creating Custom Template</span>
                  </div>
                }
              />
            )}
            {isKaraokeGenerated && (
              <div style={{ marginBottom: 10 }}>
                <TextBrandingTemplates
                  ref={ref}
                  hasStickyBottom={hasStickyBottom}
                  addNewTemplate={addNewTemplate}
                  toggleAddNewTemplate={toggleAddNewTemplate}
                  setTemplateNameError={setTemplateNameError}
                  templateNameError={templateNameError}
                  setTemplateName={setTemplateName}
                  hasActiveElement={loadedWithKaraoke}
                  type="karaoke"
                />
              </div>
            )}
            {isKaraokeGenerated && (
              <TextSettingsUI
                ref={ref}
                skipFields={['text']}
                karaokeElement={config}
                selectedBold={selectedBold}
                selectedActions={selectedActions}
                selectedFontFamily={config.font_family}
                selectedFontSize={config.font_size}
                selectedFillColor={config.fill_color}
                selectedBackgroundColor={config.background_color}
                modifyProperty={modifyProperty}
                handleToggleBold={(bold: Bold) => handleToggleBold(bold)}
                handleToggleAction={handleToggleAction}
                handleSanitizePunctuation={handleSanitizePunctuation}
                hasStickyBottom={hasStickyBottom}
                alignType={alignType}
                toggleAlign={toggleAlign}
                setAnimation={setAnimation}
                animations={
                  config.animations?.reduce((acc, animation) => {
                    if (animation.time === 'start' || animation.time === 0) {
                      acc.enter = config.instagramEffect
                        ? 'instagram'
                        : animation.type;
                    } else if (animation.time === 'end') {
                      acc.exit = animation.type;
                    }
                    return acc;
                  }, {}) || {}
                }
              />
            )}
            {isKaraokeGenerated && (
              <SaveOrCancelTemplate
                addNewTemplate={addNewTemplate}
                setTemplateName={setTemplateName}
                setTemplateNameError={setTemplateNameError}
                templateName={templateName}
                templateNameError={templateNameError}
                toggleAddNewTemplate={toggleAddNewTemplate}
                allowCustomTemplateSave={allowCustomTemplateSave}
                setAllowCustomTemplateSave={setAllowCustomTemplateSave}
                type="karaoke"
                setting="karaoke-settings"
              />
            )}
            {!addNewTemplate &&
              !allowCustomTemplateSave &&
              !videoCreator.textBrandingService.isTemplateModified && (
                <GenerateButton onClick={() => produceKaraokeText()}>
                  <CircleCheckIcon strokeColor={'black'} />
                  Generate Karaoke Text
                </GenerateButton>
              )}
          </>
        )}

        {videoCreator.karaokeLoading &&
          !videoCreator.textBrandingService.isPropsChanged && (
            <SpinningLoading
              positionTop="20px"
              text={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Generating karaoke text</span>
                </div>
              }
            />
          )}
        {videoCreator.isLoading && (
          <SpinningLoading
            positionTop="20px"
            text={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span></span>
              </div>
            }
          />
        )}
      </Main>
    );
  }),
);

export default AIKaraokeTextProducer;

const Main = styled.div`
  padding-top: 16px;
`;

const GenerateButton = styled.div`
  width: 100%;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #17c964;
  box-sizing: border-box;

  color: #03041a;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 10px;
`;
