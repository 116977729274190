import React from 'react';
import { observer } from 'mobx-react-lite';
import { ElementState } from '../../renderer/ElementState';
import { videoCreator } from '../../stores/VideoCreatorStore';
import PropertyDropdown from '../common/PropertyDropdown';

interface PropertySelectProps {
  activeElement: ElementState;
  propertyName: string;
  defaultValue: any;
  options: Array<{ caption: string; value: any }>;
}

export const PropertySelect: React.FC<PropertySelectProps> = observer(
  (props) => {
    return (
      <PropertyDropdown
        value={
          props.activeElement.source[props.propertyName] ?? props.defaultValue
        }
        onChange={async (value) => {
          await videoCreator.renderer?.applyModifications({
            [`${props.activeElement.source.id}.${props.propertyName}`]: value,
          });
        }}
        defaultValue={
          props.activeElement.source[props.propertyName] ?? props.defaultValue
        }
        values={props.options.map(({ value, caption }) => ({ value, caption }))}
      />
    );
  },
);
