import React from 'react';
import { AlbumQueryResult, Video } from '../../types.ts/story';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Studio } from '../../types.ts/general';
import { convertTimeToMMSS } from '../../utility/timeFormat';
import SocialShareStatus from '../content-components/SocialShareStatus';
import SocialStats from '../content-components/SocialStats';

type Props = {
  stories: AlbumQueryResult['stories'];
  resetStoryStates(storyId: string): void;
  buildTargetUrl(
    params: URLSearchParams,
    storyId: string,
    playbackId: string | undefined,
  ): string;
};
const StoryDashboardContent = observer((props: Props) => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  const renderStoryTableContent = (story: AlbumQueryResult['stories'][0]) => {
    const currentVideo =
      (story?.finalVideo as Video | undefined)?.videoFilePrimary?.video ||
      story?.originalVideo?.video;

    const getStoryThumbnailUrl = (story: AlbumQueryResult['stories'][0]) => {
      if (story.thumbnail?.url) return story.thumbnail?.url;
      if (story?.finalVideo?.thumbnail?.url) {
        return story?.finalVideo?.thumbnail?.url;
      }

      return story?.originalVideo?.video?.thumbnailUrl;
    };

    const videoDuration = (story: AlbumQueryResult['stories'][0]) => {
      const finalVideoDuration =
        story?.finalVideo?.videoFilePrimary?.video?.duration;
      if (finalVideoDuration) {
        return convertTimeToMMSS(finalVideoDuration);
      }

      const originalVideoDuration = story?.originalVideo?.video?.duration;

      if (originalVideoDuration) {
        return convertTimeToMMSS(Number(originalVideoDuration));
      }
    };

    const getDescription = (story: AlbumQueryResult['stories'][0]) => {
      const descriptionResponse = story?.aiResponse?.responses?.find(
        (response) => response.title === 'Description',
      );
      return descriptionResponse?.response?.toString() || '';
    };

    return (
      <>
        <HorizontalLine />
        <Row>
          <RowLink
            to={`/${Studio.content}${props.buildTargetUrl(
              urlSearchParams,
              story.id,
              currentVideo.muxPlaybackId,
            )}`}
            onClick={() => props.resetStoryStates(story.id)}
          >
            <ThumbnailColumn>
              <ThumbContainer>
                <Thumb src={getStoryThumbnailUrl(story)} />
              </ThumbContainer>
              <Info>
                <span className="story-title">{story.title}</span>
                <span className="teller-name">{story.storyTeller.name}</span>
                <span className="duration">{videoDuration(story)}</span>
              </Info>
            </ThumbnailColumn>

            {/* <StoryTeller>{story.storyTeller.name}</StoryTeller> */}

            <StoryDescription>{getDescription(story)}</StoryDescription>
            <Platform>
              <SocialShareStatus
                showOnlyShared={true}
                sharedContents={story?._allReferencingSharedContents}
              />
            </Platform>
            <Analytics>
              <SocialStats
                type="story"
                id={story?.id}
                allReferencingSharedContents={
                  story?._allReferencingSharedContents
                }
                layout="dashboard"
              />
            </Analytics>

            <PublishedDate>
              {new Date(story._publishedAt).toLocaleDateString()}
            </PublishedDate>
          </RowLink>
        </Row>
      </>
    );
  };

  const renderStoryTableHeader = () => (
    <>
      <RowHeader>
        <ThumbnailHeader>
          <span>Story</span>
        </ThumbnailHeader>
        {/* <StoryTellerHeader>
              <span>Storyteller</span>
            </StoryTellerHeader> */}
        <SummaryHeader>
          <span>Summary</span>
        </SummaryHeader>
        <PlatformHeader>
          <span>Platform</span>
        </PlatformHeader>
        <AnalyticsHeader>
          <span>Analytics</span>
        </AnalyticsHeader>

        <DateHeader>
          <span>Published</span>
        </DateHeader>
      </RowHeader>
    </>
  );

  const storyContent = props.stories?.filter(
    (s) => s.finalVideo || s.originalVideo,
  );
  const storyHeader = {} as AlbumQueryResult['stories'][0];

  const storyData = [storyHeader, ...storyContent];
  return (
    <>
      {storyData.map((story, index) => {
        return (
          <div key={story.id}>
            {index === 0
              ? renderStoryTableHeader()
              : renderStoryTableContent(story)}
          </div>
        );
      })}
    </>
  );
});

export default StoryDashboardContent;

const HorizontalLine = styled.div`
  min-width: 1190px;
  width: 100%;
  height: 1px;
  background-color: #848484;
`;

const Row = styled.div`
  display: flex;
  // border-top: 1px solid #848484;
  height: 90px;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const RowHeader = styled(Row)`
  border-top: unset;
  height: unset;
  margin-top: 20px;
  padding: 0 5px;
  padding-bottom: 10px;
  text-align: left;
`;
const Column = styled.div`
  height: 90px;
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 183px;
  width: 183px;
`;

const ThumbnailColumn = styled(Column)`
  gap: 15px;
  min-width: 380px;
`;

const ThumbnailHeader = styled(ThumbnailColumn)`
  gap: 8px;
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  align-items: center;
  width: 350px;
  height: 100%;
`;
const ColumnHeader = styled(Column)`
  text-align: left;
  min-width: 200px;
  width: 200px;
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  height: 100%;
`;

const SummaryHeader = styled(ColumnHeader)`
  width: 300px;
  min-width: 300px;
  height: 100%;
`;

const Thumb = styled.img`
  width: 140px;
  height: 90px;
  border-radius: 8px;
  object-fit: cover;
  object-position: top;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1,
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 2px;
  .story-title {
    font-weight: 700;
    font-size: 14px;
    color: #f3e9d7;
    text-overflow: ellipsis;
  }
  .teller-name,
  .duration {
    font-weight: 500;
    font-size: 12px;
    color: #848484;
  }
`;

const StoryDescription = styled(Column)`
  font-weight: 400;
  font-size: 12px;
  width: 300px;
  min-width: 300px;

  line-height: 15px;
  color: #f3e9d7;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
  max-height: 90px;
`;

const StoryTeller = styled(Column)`
  width: 190px;
  min-width: 200px;
  font-weight: 500;
  font-size: 12px;
  color: #848484;
`;

const Platform = styled(Column)`
  width: 170px;
  min-width: 170px;
  font-weight: 500;
  font-size: 12px;
  color: #848484;
`;

const PlatformHeader = styled(Platform)`
  height: 100%;
`;

const Analytics = styled(Column)`
  width: 100px;
  min-width: 100px;
  // font-weight: 500;
`;

const AnalyticsHeader = styled(Analytics)`
  font-size: 12px;
  color: #848484;
  height: 100%;
`;

const PublishedDate = styled(Column)`
  font-weight: 400;
  text-align: right;
  color: #f3e9d7;
  margin-left: 50px;
  justify-content: flex-end;
  width: 80px;
  min-width: 80px;
`;

const DateHeader = styled(PublishedDate)`
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  height: 100%;
`;

const RowLink = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  align-items: center;
  min-width: 1190px;
  width: 100%;
  height: 100px;
  cursor: pointer;
  &:hover {
    background-color: #48484866;
    border-radius: 8px;
    .view-clip {
      display: flex;
    }
  }
`;

const ThumbContainer = styled.div`
  height: 100%;
  position: relative;
`;
