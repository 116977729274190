import React from 'react';
import { observer } from 'mobx-react-lite';
import { ElementState } from '../../renderer/ElementState';
import { videoCreator } from '../../stores/VideoCreatorStore';
import PropertyDropdown from '../common/PropertyDropdown';
import { v4 as uuid } from 'uuid';

type Props = {
  activeElement: ElementState;
  propertyName: string;
  defaultValue: any;
  options: Array<{ caption: string; value: any }>;
};
const PROPS_TO_EXCLUDE = [
  'panLeft',
  'panRight',
  'panLeftWithZoom',
  'panRightWithZoom',
];

const ImageFitPropertySelect: React.FC<Props> = observer((props) => {
  const elementId = props.activeElement.source.id;
  function getActiveProperty() {
    if (videoCreator.isImageElementComposition(props.activeElement)) {
      return 'black-frame';
    }
    return props.activeElement.source[props.propertyName] ?? props.defaultValue;
  }
  const source = videoCreator.getImageElement(props.activeElement)?.source;

  return (
    <PropertyDropdown
      value={getActiveProperty()}
      onChange={async (value) => {
        const properties: Record<string, any> = {};

        properties[`${elementId}.width`] = '100%';
        properties[`${elementId}.height`] = '100%';
        properties[`${elementId}.x`] = '50%';
        properties[`${elementId}.y`] = '50%';

        if (value === 'black-frame') {
          const animations =
            source?.animations?.map((a: any) => {
              if (PROPS_TO_EXCLUDE.includes(a.arbor_subType)) {
                return {
                  easing: 'linear',
                  type: 'scale',
                  arbor_subType: 'zoomIn',
                  scope: 'element',
                  start_scale: '100%',
                  end_scale: '130%',
                  fade: false,
                };
              }
              return a;
            }) || [];

          properties[`${elementId}.type`] = 'composition';
          properties[`${elementId}.elements`] = [
            {
              ...(source || {}),
              id: uuid(),
              fit: 'contain',
              time: 0,
              track: 2,

              x: '50%',
              y: '50%',
              height: '100%',
              locked: true,
              animations: [],
              color_overlay: '',
            },

            {
              id: uuid(),
              type: 'shape',
              time: 0,
              track: 1,
              duration: props.activeElement.duration,

              width: '100%',
              height: '100%',
              x: '50%',
              y: '50%',

              fill_color: '#000',
              locked: true,

              path: 'M 0 0 L 100 0 L 100 100 L 0 100 L 0 0 Z',
            },
          ];

          properties[`${elementId}.animations`] = [];
          properties[`${elementId}.width`] = '100%';
          properties[`${elementId}.height`] = '100%';
          properties[`${elementId}.animations`] = animations || [];
          properties[`${elementId}.color_overlay`] =
            source?.color_overlay || '';
        } else {
          properties[`${elementId}.${props.propertyName}`] = value;
          properties[`${elementId}.source`] = source?.source;
          properties[`${elementId}.locked`] = false;
          properties[`${elementId}.type`] = 'image';
          properties[`${elementId}.animations`] =
            props.activeElement.source?.animations || [];
          properties[`${elementId}.color_overlay`] =
            props.activeElement.source?.color_overlay || '';
        }

        await videoCreator.renderer?.applyModifications({
          ...properties,
        });
        await videoCreator.setActiveElements(elementId);
      }}
      defaultValue={getActiveProperty()}
      values={props.options.map(({ value, caption }) => ({ value, caption }))}
    />
  );
});

export default ImageFitPropertySelect;
