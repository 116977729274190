import { Canvas, Button, FieldError } from 'datocms-react-ui';
import { RenderFieldExtensionCtx } from 'datocms-plugin-sdk';
import { buildClient, LogLevel } from '@datocms/cma-client-browser';
import { createGQLClient } from '../utility/dato';
import { StoryRepository } from '../repositories/StoryRepository';
import { Story, VideoClip } from '../types.ts/story';
import { useState } from 'react';
import styled from 'styled-components';
import { ShareModalAnimate } from '../styles/mainStyle';

type PropTypes = {
  ctx: RenderFieldExtensionCtx;
};

export default function UpdateClipSource({ ctx }: PropTypes) {
  const [error, setError] = useState<string>();
  const [updating, setUpdating] = useState<boolean>(false);
  const dClient = buildClient({
    apiToken: ctx.currentUserAccessToken || null,
    environment: ctx.environment,
    logLevel: LogLevel.NONE,
  });

  const gqlClient = createGQLClient({
    includeDrafts: true,
    excludeInvalid: true,
    environment: ctx.environment,
    authToken: process.env.REACT_APP_DATOCMS_READ_API_TOKEN!,
  });

  const storyRepository = new StoryRepository(dClient!, gqlClient!);

  const isOriginalVideo = (
    url: string,
    story: Pick<Story, 'originalVideo' | 'previousOriginalVideo'>,
  ) => {
    return (
      url.includes(story.previousOriginalVideo.video.muxPlaybackId) ||
      url === story.previousOriginalVideo.url
    );
  };

  const handleUpdateClipSource = async () => {
    try {
      const storyId = ctx.item?.id!;
      const story = await storyRepository.getAllVideoSourcesInStoryId(storyId);

      const videos =
        story?.otherVideos?.flatMap((v) => [v, ...v.associatedVideos]) || [];
      if (!story?.previousOriginalVideo) {
        setError(
          'Please replace the original video with a new video and make sure you hit Save.',
        );
        return;
      }

      if (!videos.length) {
        setError(
          'You have no sub-clips to update that are connected to the original video',
        );
        return;
      }

      if (
        story.previousOriginalVideo.video.duration !==
        story.originalVideo.video.duration
      ) {
        setError(
          'Duration of new video does not match. This will interfere with existing clips. Please regenerate the transcript to ensure consistency.',
        );
      } else {
        setError('');
      }

      setUpdating(true);

      for (let clip of videos) {
        const videoJson = clip.videoJson;

        const modifiedJsonElements = videoJson.elements.map((v: any) => {
          if (
            v.type === 'video' &&
            v.source &&
            isOriginalVideo(v.source, story!)
          ) {
            return {
              ...v,
              source: story?.originalVideo.url,
            };
          }
          return v;
        });
        videoJson.elements = modifiedJsonElements;

        await dClient.items.update(clip.id!, {
          video_json: JSON.stringify(videoJson),
        });
      }
      setUpdating(false);

      await dClient.items.update(storyId, {
        previous_original_video: null,
      });
      await dClient.items.publish(storyId);
    } catch (err: any) {
      console.error('Error in UpdateClipSource handler', err);
      setError(`API Error, check logs`);
    }
  };

  return (
    <Canvas ctx={ctx}>
      <Button type="button" onClick={handleUpdateClipSource}>
        <div
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          <span>Update Clips Source </span>
          {updating && <Animate color="#d49417" />}
        </div>
      </Button>
      {error && <FieldError>{error}</FieldError>}
    </Canvas>
  );
}

const Animate = styled(ShareModalAnimate)``;
