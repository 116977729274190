import { InstagramAnimationLines } from '../components/sidepanel/AnimationSettingsUI';
import { Renderer } from '../renderer/Renderer';
import {
  KARAOKE_TRACK_NUMBER,
  videoCreator,
} from '../stores/VideoCreatorStore';
import {
  TranscriptElement,
  TranscriptPunctElement,
  TranscriptTextElement,
  convertFromPixels,
  getClosestElementIndexToLeftByFilter,
  getClosestElementIndexToRightByFilter,
  getClosestNotRemovedElementIndexToLeft,
  getClosestNotRemovedElementIndexToRight,
  getClosestNotRemovedTextIndexToLeft,
  getClosestNotRemovedTextIndexToRight,
} from './utils';
import { v4 as uuid } from 'uuid';

export type KaraokeElement = {
  id: string;
  ts: number;
  endTs: number;
  text: string;
};

export type KaraokeConfig = {
  width: string;
  font_size: string;
  font_weight: string;
  fill_color: string;
  background_color: string | null;
  text_wrap: boolean;
  x_alignment: string;
  x: string | null;
  y: string;
  line_height: string;
  font_family: string;
  font_style?: string;
  text_transform?: string;
  stroke_color: string;
  stroke_width: string;
  animations: any[];
  // maxCharactersPerElement: number; hardcoded in produceKaraokeElements
  hideComma: boolean;
  hidePeriod: boolean;
  hideFillers: boolean;
  instagramEffect: boolean;
  instagramLines: keyof typeof InstagramAnimationLines;
  language: 'original' | 'english';
};

const MAX_GAP_DURATION = 1; // in second
export const MAX_CHARS_INSTAGRAM = 14;
export const MAX_CHARS_DEFAULT = 50;
const FILLER_WORDS = [
  'Umm',
  'Um',
  'Uh',
  'Uhh',
  'Eh',
  'Just',
  'You know',
  'Ya know',
  'Well',
  'So',
  'Actually',
  'Basically',
  'I mean',
  'Really',
  'Hmm',
  'Hm',
  'Ah',
  'Ahh',
  'Er',
];

export const DEFAULT_KARAOKE_CONFIG = {
  width: '76%',
  font_size: '3.333vh',
  font_weight: '600',
  fill_color: 'white',
  background_color: 'rgba(0,0,0,0.8)',
  text_wrap: true,
  x_alignment: '50%',
  background_align_threshold: '15%',
  y: '86%',
  x: '50%',
  line_height: '125%',
  font_family: 'Inter',
  font_style: 'normal',
  stroke_color: 'transparent',
  stroke_width: '0.25 vmin',
  animations: [] as any[],
  // maxCharactersPerElement: MAX_CHARS_DEFAULT, hardcoded in produceKaraokeElements
  hideComma: false,
  hidePeriod: false,
  hideFillers: false,
  instagramEffect: false,
  instagramLines: 5,
  language: 'original',
} as KaraokeConfig;

const getParts = (element: TranscriptElement): TranscriptElement[] => {
  let part;
  const text = element.value!;
  if (text.length === 0) {
    return [element];
  }

  if (text.trim().length === 0) {
    part = element;
    part.value = text[0];
    return [part];
  }

  if (text.trim().split(' ').length === 1 || element.type !== 'text')
    return [element];

  const parts = text.split(' ');
  const elementDuration = element.end_ts! - element.ts!;
  const partDuration = elementDuration / parts.filter((p) => !!p).length;
  const newElements = parts
    .flatMap((e, ind) => [
      e
        ? ({
            type: 'text',
            value: e,
            ts: element.ts + ind * partDuration,
            end_ts: element.ts! + (ind + 1) * partDuration,
          } as TranscriptTextElement)
        : null,
      e && ind < parts.length - 1
        ? ({
            type: 'punct',
            value: ' ',
          } as TranscriptPunctElement)
        : null,
    ])
    .filter((p) => p && p.value) as TranscriptElement[];
  if (element.karaoke_break) {
    newElements[newElements.length - 1].karaoke_break = true;
  }
  if (element.karaoke_break_start_ts_diff) {
    newElements.find((el) => el.type === 'text')!.karaoke_break_start_ts_diff =
      element.karaoke_break_start_ts_diff;
  }
  if (element.karaoke_break_end_ts_diff) {
    //@ts-ignore
    newElements.findLast(
      (el: TranscriptElement) => el.type === 'text',
    )!.karaoke_break_end_ts_diff = element.karaoke_break_end_ts_diff;
  }
  return newElements;
};

export default class KaraokeProducer {
  private transcriptionElements?: TranscriptElement[];
  private renderer?: Renderer;
  private karaokeElements?: KaraokeElement[];
  private karaokeConfig: KaraokeConfig = DEFAULT_KARAOKE_CONFIG;

  hasElements() {
    return Boolean(this.karaokeElements && this.karaokeElements.length > 0);
  }

  setRenderer(renderer: Renderer) {
    this.renderer = renderer;
  }

  getKaraokeConfig() {
    return this.karaokeConfig;
  }

  setTranscriptionElements(transcriptionElements: TranscriptElement[]) {
    this.transcriptionElements = transcriptionElements;
  }

  getKaraokeTextSettingByAspectRatio(
    aspectRatio: '16:9' | '1:1' | '9:16' = videoCreator.currentVideo
      ?.aspectRatio || '16:9',
  ) {
    switch (aspectRatio) {
      case '16:9':
        return {
          font_size: '36',
          y: '86%',
          width: '76%',
        };
      case '1:1':
        return {
          font_size: '30',
          y: '84%',
          width: '76%',
        };
      case '9:16':
        return {
          font_size: '24',
          y: '75%',
          width: '82%',
        };
      default:
        return {
          font_size: '36',
          y: '86%',
          width: '76%',
        };
    }
  }

  async deleteKaraokeElements() {
    const source = this.renderer!.getSource();
    source.elements = source.elements.filter(
      (el: any) => el.track < KARAOKE_TRACK_NUMBER,
    );
    await this.renderer!.setSource(source);
    this.karaokeElements = [];
  }

  public karaokeElement() {
    const source = this.renderer!.getSource();
    const element = source?.elements?.find(
      (el: any) => el.track === KARAOKE_TRACK_NUMBER,
    );
    return element;
  }

  createKaraokeBreaksFromExistingElements() {
    const karaokeElements = this.karaokeElements;
    const transcriptElements = this.transcriptionElements!;
    if (!karaokeElements) return;
    const transcriptPositionsToBreak: number[] = [];
    let currentTranscriptElement = getClosestNotRemovedTextIndexToRight(
      0,
      transcriptElements,
    );
    for (let i = 0; i < karaokeElements.length; i++) {
      const element = karaokeElements[i];
      const firstElementAfterStartTs = getClosestElementIndexToRightByFilter(
        currentTranscriptElement,
        transcriptElements,
        (el) =>
          Boolean(
            el.state !== 'removed' &&
              el.state !== 'cut' &&
              el.state !== 'muted' &&
              el.value &&
              el.ts &&
              el.ts > element.ts - 1,
          ),
      );
      const lastElementAfterEndTs = getClosestElementIndexToRightByFilter(
        currentTranscriptElement,
        transcriptElements,
        (el) =>
          Boolean(
            el.state !== 'removed' &&
              el.state !== 'cut' &&
              el.state !== 'muted' &&
              el.value &&
              el.end_ts &&
              el.end_ts > element.endTs + 1,
          ),
      );
      const slice = transcriptElements.slice(
        firstElementAfterStartTs,
        lastElementAfterEndTs + 1,
      );
      const karaokeLineParts = element.text.match(/(\w+(?:('|-)\w+)*)/g);
      if (!karaokeLineParts || karaokeLineParts.length < 2) continue;
      const start = slice.findIndex(
        (el, index) =>
          karaokeLineParts[0].toLowerCase() === el.value?.toLowerCase() &&
          slice[
            getClosestNotRemovedTextIndexToRight(index + 1, slice)
          ].value?.toLowerCase() === karaokeLineParts[1].toLowerCase(),
      );

      //@ts-ignore
      let end = slice.findLastIndex(
        (el: TranscriptElement, index: number) =>
          karaokeLineParts.at(-1)!.toLowerCase() === el.value?.toLowerCase() &&
          slice[
            getClosestNotRemovedTextIndexToLeft(index - 1, slice)
          ].value?.toLowerCase() === karaokeLineParts.at(-2)!.toLowerCase(),
      );

      if (start !== -1 && transcriptPositionsToBreak.length > 0) {
        const newBreak = getClosestNotRemovedElementIndexToLeft(
          firstElementAfterStartTs + start - 1,
          transcriptElements,
        );
        if (newBreak > 0 && transcriptPositionsToBreak.at(-1) !== newBreak) {
          transcriptPositionsToBreak.push(newBreak);
        }
      }

      if (end !== -1) {
        const nextElem = getClosestNotRemovedTextIndexToRight(
          firstElementAfterStartTs + end + 1,
          transcriptElements,
        );
        end =
          nextElem > 0
            ? getClosestNotRemovedElementIndexToLeft(
                nextElem - 1,
                transcriptElements,
              )
            : firstElementAfterStartTs + end;
        transcriptPositionsToBreak.push(end);
      }

      currentTranscriptElement = firstElementAfterStartTs;
    }
    videoCreator.removeAllKaraokeBreaks();
    videoCreator.addKaraokeBreaks(transcriptPositionsToBreak);
  }

  setKaraokeElementsFromSource(source: any) {
    // parse source elements to restore karaoke elements
    const sourceElements = source?.elements?.filter(
      (el: any) => parseInt(el.track) === KARAOKE_TRACK_NUMBER,
    );

    if (sourceElements?.length === 0) {
      this.karaokeElements = [];
      return;
    }

    if (sourceElements?.[0]?.type === 'composition') {
      this.karaokeElements = sourceElements.flatMap((el: any) => {
        const compositionTs = parseFloat(el.time);
        return el.elements.map((subEl: any) => ({
          id: subEl.id,
          ts: compositionTs + parseFloat(subEl.time),
          endTs:
            compositionTs + parseFloat(subEl.time) + parseFloat(subEl.duration),
          text: subEl.text,
        }));
      });
    }

    if (sourceElements?.[0]?.type === 'text') {
      this.karaokeElements = sourceElements.map((el: any) => ({
        id: el.id,
        ts: parseFloat(el.time),
        endTs: parseFloat(el.time) + parseFloat(el.duration),
        text: el.text,
      }));
    }
  }

  setConfig(config: Partial<KaraokeConfig>) {
    this.karaokeConfig = {
      ...DEFAULT_KARAOKE_CONFIG,
      ...this.getKaraokeTextSettingByAspectRatio(),
      ...config,
    };
  }

  async rerenderWithNewConfig(newConfig: Partial<KaraokeConfig>) {
    const oldConfig = this.karaokeConfig;
    this.setConfig(newConfig);
    const isInstagram = newConfig.instagramEffect;
    const wasInstagram = oldConfig.instagramEffect;
    videoCreator.karaokeLoading = true;
    if (isInstagram !== wasInstagram) {
      this.karaokeElements = [];
      videoCreator.removeAllKaraokeBreaks();
      await this.produceKaraoke();
    } else if (!isInstagram) {
      await this.renderKaraokeElements();
    } else {
      await this.renderInstagramElements();
    }
    videoCreator.karaokeLoading = false;
  }

  async produceKaraoke(
    config?: KaraokeConfig,
    addBreaksForRange?: { fromIndex: number; toIndex: number },
  ) {
    videoCreator.karaokeLoading = true;
    const originalLanguage = videoCreator.originalTranscription?.language;
    this.karaokeConfig = config || this.karaokeConfig;
    if (!this.karaokeConfig) throw new Error('No config provided');

    if (
      !originalLanguage?.includes('en') &&
      this.karaokeConfig.language.includes('en')
    ) {
      await this.produceKaraokeElementsFromSubtitles();
    } else {
      const onlyOnTranscriptBreaks = this.hasElements() && !addBreaksForRange;
      this.produceKaraokeElements(
        this.karaokeConfig.instagramEffect
          ? MAX_CHARS_INSTAGRAM
          : MAX_CHARS_DEFAULT,
        onlyOnTranscriptBreaks,
        addBreaksForRange,
      );
    }

    if (this.karaokeConfig.instagramEffect) {
      await this.renderInstagramElements();
    } else {
      await this.renderKaraokeElements();
    }
    videoCreator.karaokeLoading = false;
    // this.saveExtraElementsData();
  }

  async produceKaraokeElementsFromSubtitles() {
    let subtitles = videoCreator.currentVideo?.subtitles;
    if (!subtitles) {
      const generatedSubtitles =
        await videoCreator.requestSubtitlesForCurrentVideo();
      if (!generatedSubtitles) {
        throw new Error('No subtitles generated');
      }
      subtitles = generatedSubtitles;
    }
    this.karaokeElements = subtitles.lines.map((line) => ({
      id: uuid(),
      ts: line.start,
      endTs: line.end,
      text: line.translated,
    }));
  }

  shouldBreakOnElement(
    element: TranscriptElement,
    part: Pick<TranscriptElement, 'type' | 'value'>,
    currentKaraokeElement: KaraokeElement,
    maxCharsInLine: number,
    isStartOfSentence: boolean,
    isAfterComma: boolean,
    onlyOnTranscriptBreaks: boolean,
  ) {
    return onlyOnTranscriptBreaks
      ? Boolean(isStartOfSentence)
      : part.type === 'text' &&
          part.value &&
          currentKaraokeElement.text.trim().length > 0 &&
          (currentKaraokeElement.text.length + part.value!.length >
            maxCharsInLine ||
            element.ts! - currentKaraokeElement.endTs > 2 * MAX_GAP_DURATION ||
            isStartOfSentence ||
            (isAfterComma &&
              currentKaraokeElement.text.length >
                Math.round(0.7 * maxCharsInLine)));
  }

  produceKaraokeElements(
    maxCharsInLine: number = 12,
    onlyOnTranscriptBreaks: boolean,
    addBreaksForRange?: { fromIndex: number; toIndex: number },
  ) {
    const resultedKaraokeElements: KaraokeElement[] = [];
    let currentKaraokeElement: KaraokeElement = {
      id: uuid(),
      ts: -1,
      endTs: -1,
      text: '',
    };
    let isStartOfSentence = true;
    let isAfterComma = false;
    let lastAddedElementIndex = -1;
    let karaokeBreakStartTsDiff = 0;
    let allowBreaks = !onlyOnTranscriptBreaks;

    if (addBreaksForRange) {
      let previousBreak = getClosestElementIndexToLeftByFilter(
        addBreaksForRange.fromIndex,
        this.transcriptionElements!,
        (el, idx) => !!el.karaoke_break,
      );
      if (previousBreak > 0) {
        videoCreator.videoTranscriptionProcessor.removeKaraokeBreak(
          previousBreak,
        );
        previousBreak = getClosestElementIndexToLeftByFilter(
          previousBreak - 1,
          this.transcriptionElements!,
          (el, idx) => !!el.karaoke_break,
        );
      }
      if (previousBreak > 0) {
        addBreaksForRange.fromIndex = previousBreak;
      } else {
        addBreaksForRange.fromIndex = 0;
      }
      let nextBreak = getClosestElementIndexToRightByFilter(
        addBreaksForRange.toIndex,
        this.transcriptionElements!,
        (el, idx) => !!el.karaoke_break,
      );
      if (nextBreak > 0) {
        videoCreator.videoTranscriptionProcessor.removeKaraokeBreak(nextBreak);
        nextBreak = getClosestElementIndexToRightByFilter(
          nextBreak + 1,
          this.transcriptionElements!,
          (el, idx) => !!el.karaoke_break,
        );
      }
      if (nextBreak > 0) {
        addBreaksForRange.toIndex = nextBreak;
      } else {
        addBreaksForRange.toIndex = this.transcriptionElements!.length - 1;
      }
    }

    const transcriptPositionsToBreak: number[] = [];
    for (let i = 0; i < this.transcriptionElements!.length; i++) {
      const element = this.transcriptionElements![i];

      if (addBreaksForRange) {
        allowBreaks =
          i >= addBreaksForRange.fromIndex && i < addBreaksForRange.toIndex;
      }

      if (
        element.state === 'removed' ||
        element.state === 'cut' ||
        (!onlyOnTranscriptBreaks &&
          (element.state === 'muted' || !element.value))
      )
        continue;

      const parts = getParts(element);
      // in case of one transcription element contains multiple words
      for (const part of parts) {
        let shouldBreak = this.shouldBreakOnElement(
          element,
          part,
          currentKaraokeElement,
          maxCharsInLine,
          isStartOfSentence,
          isAfterComma,
          !allowBreaks,
        );

        if (shouldBreak) {
          currentKaraokeElement.text = currentKaraokeElement.text.trim();
          if (currentKaraokeElement.ts > currentKaraokeElement.endTs) {
            // todo temp hotfix
            const endTs = currentKaraokeElement.ts;
            currentKaraokeElement.endTs = currentKaraokeElement.ts;
            currentKaraokeElement.ts = endTs;
          }
          if (allowBreaks) {
            transcriptPositionsToBreak.push(lastAddedElementIndex);
          }
          resultedKaraokeElements.push(currentKaraokeElement);
          currentKaraokeElement = {
            id: uuid(),
            ts: -1,
            endTs: -1,
            text: '',
          };
          karaokeBreakStartTsDiff = 0;
        }

        isStartOfSentence = !allowBreaks
          ? Boolean(part.karaoke_break)
          : part.karaoke_break ||
            element.value === '.' ||
            element.value === '?' ||
            element.value === '!' ||
            (isStartOfSentence && element.type !== 'text');

        isAfterComma =
          element.value === ',' || (isAfterComma && element.type !== 'text');

        if (element.state !== 'muted' && element.value) {
          currentKaraokeElement.text += part.value;
          lastAddedElementIndex = i;
        }

        if (part.type === 'text') {
          currentKaraokeElement.ts =
            currentKaraokeElement.ts < 0
              ? part.ts! + karaokeBreakStartTsDiff
              : currentKaraokeElement.ts;
          currentKaraokeElement.endTs = part.end_ts!;
        }
        if (part.karaoke_break_start_ts_diff != null) {
          if (currentKaraokeElement.ts < 0) {
            karaokeBreakStartTsDiff = part.karaoke_break_start_ts_diff;
          } else {
            currentKaraokeElement.ts =
              currentKaraokeElement.ts + part.karaoke_break_start_ts_diff;
          }
        }

        if (
          currentKaraokeElement.endTs &&
          part.karaoke_break_end_ts_diff != null
        ) {
          currentKaraokeElement.endTs =
            currentKaraokeElement.endTs + part.karaoke_break_end_ts_diff;
        }
      }
    }
    if (currentKaraokeElement.text.trim().length > 0) {
      if (currentKaraokeElement.ts > currentKaraokeElement.endTs) {
        // todo temp fix
        const endTs = currentKaraokeElement.ts;
        currentKaraokeElement.endTs = currentKaraokeElement.ts;
        currentKaraokeElement.ts = endTs;
      }
      resultedKaraokeElements.push(currentKaraokeElement);
    }

    if (transcriptPositionsToBreak.length > 0) {
      videoCreator.addKaraokeBreaks(transcriptPositionsToBreak);
    }
    this.karaokeElements = resultedKaraokeElements.filter(
      (el) => el.ts >= 0 && el.text.trim().length > 0,
    );
  }

  estimateTextWidth(text: string, fontSize: number) {
    return text.length * fontSize * 0.6;
  }

  private sanitizedText(rawText: string, config: KaraokeConfig) {
    let text = rawText;
    if (config.hideFillers) {
      text = this.capitalizeAfterPeriods(this.removeFillerWords(text).trim());
    }

    if (config.hideComma) {
      text = text.replaceAll(',', '');
    }
    if (config.hidePeriod) {
      text = text.replaceAll('.', '');
    }
    return text;
  }

  private removeFillerWords(text: string) {
    const fillerRegex = new RegExp(
      `(?:\\b|^)(?:${FILLER_WORDS.join('|')})(?:,\\s*)?(?=\\b|$)`,
      'gi',
    );
    return text.replace(fillerRegex, '');
  }

  private capitalizeAfterPeriods(text: string): string {
    let capitalizeNext = true;
    return text.replace(
      /([.!?])\s*(\w)/g,
      (match: string, punctuation: string, letter: string): string => {
        if (capitalizeNext) {
          capitalizeNext = false;
          return punctuation + ' ' + letter.toUpperCase();
        } else {
          return match;
        }
      },
    );
  }

  transformToInstagramElements(
    elements: KaraokeElement[],
    config: KaraokeConfig,
  ) {
    const source = this.renderer!.getSource();
    const videoWidth = source.width;
    const videoHeight = source.height;
    let avgFontSize = parseFloat(config.font_size);
    let fontSizeUnits = config.font_size.match(/[a-z]+/)?.[0] || 'px';
    const lines = Number(config.instagramLines) || 5;
    const largeLines = Math.floor((2 * lines) / 5);

    if (fontSizeUnits === 'px') {
      avgFontSize = convertFromPixels(avgFontSize, 'vh', {
        width: videoWidth,
        height: videoHeight,
      });
      fontSizeUnits = 'vh';
    }

    const instagramElements: (KaraokeElement & {
      fontSize?: number;
      size?: 'large' | 'small';
    })[][] = [];

    let currentInstagramElement = [];
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      if (
        currentInstagramElement.length === lines ||
        (i > 0 && element.ts - elements[i - 1].endTs > MAX_GAP_DURATION)
      ) {
        instagramElements.push(currentInstagramElement);
        currentInstagramElement = [];
      }

      currentInstagramElement.push(element);
    }
    // last one
    if (currentInstagramElement.length > 0) {
      instagramElements.push(currentInstagramElement);
    }

    const resultedKaraokeElements = [];
    for (const instagramElement of instagramElements) {
      const karaokeLines = [];
      const elementTs = instagramElement[0].ts;
      const elementEndTs = instagramElement[instagramElement.length - 1].endTs;

      const secondsPerCharInLine = instagramElement.map((el, index) => ({
        index,
        tsPerChar: (el.endTs - el.ts) / el.text.length,
      }));

      secondsPerCharInLine.sort((a, b) => b.tsPerChar - a.tsPerChar);
      secondsPerCharInLine.slice(0, largeLines).forEach((el) => {
        instagramElement[el.index].fontSize = Math.round(
          1.15 * avgFontSize * (1 + Math.random() * 0.1),
        );
        instagramElement[el.index].size = 'large';
      });
      secondsPerCharInLine.slice(largeLines).forEach((el) => {
        instagramElement[el.index].fontSize = Math.round(
          0.75 * avgFontSize * (1 + Math.random() * 0.1),
        );
        instagramElement[el.index].size = 'small';
      });

      // TODO replace with width and height params
      // + composition position
      let linePosY = parseFloat(config.y); // Math.round((parseFloat(config.y) * videoHeight) / 100);
      let linePosX = videoWidth / 2;

      for (let k = 0; k < instagramElement.length; k++) {
        const karaokeLine = instagramElement[k];
        const nextLine = instagramElement[k + 1];
        const time = Math.max(elementTs, karaokeLine.ts - 0.2);
        const duration = elementEndTs - time;
        karaokeLines.push({
          id: uuid(),
          time,
          duration,
          type: 'text',
          text: this.sanitizedText(karaokeLine.text, config),
          background_y_padding: '5%',
          background_x_padding:
            Math.round((avgFontSize / karaokeLine.fontSize!) * 16) + '%',
          ...config,
          // IMPORTANT: values below override config
          text_transform: 'uppercase',
          font_size: null, //currentWordElement.fontSize!,
          height: `${Math.ceil(1.15 * karaokeLine.fontSize!)} ${fontSizeUnits}`,
          y: `${linePosY} vh`,
          x: linePosX,
          animations: config.animations.map((anim: any) => ({
            ...anim,
            duration: 0.1, // Math.max(karaokeLine.endTs! - karaokeLine.ts!, 0.1),
            fade: false,
            background_effect: 'animated',
          })),
        });

        linePosY += Math.ceil(
          0.55 * karaokeLine.fontSize! + 0.55 * (nextLine?.fontSize || 0),
        );
      }
      resultedKaraokeElements.push(karaokeLines);
    }

    return resultedKaraokeElements;
  }

  getCompositionElement(elements: any[]): {
    time: any;
    type: string;
    track: number;
    elements: any[];
    [key: string]: any; // Index signature
  } {
    const relativeTime = elements[0].time;
    return {
      time: relativeTime,
      type: 'composition',
      track: KARAOKE_TRACK_NUMBER,
      elements: elements.map((el, index) => {
        el.time -= relativeTime;
        el.track = index + 1; // track inside composition
        return el;
      }),
    };
  }

  async renderInstagramElements() {
    if (!this.karaokeConfig) throw new Error('No karaoke config');

    const source = this.renderer!.getSource();
    let track = KARAOKE_TRACK_NUMBER; //karaoke is fixed on top track

    source.elements = source.elements.filter((el: any) => el.track < track);
    source.elements = [
      ...source.elements,
      ...this.transformToInstagramElements(
        this.karaokeElements!,
        this.karaokeConfig,
      ).map((karaokeElement: any[]) => {
        let composition = this.getCompositionElement(karaokeElement);
        composition.x = this.karaokeConfig!.x;
        return composition;
      }),
    ];
    await this.renderer!.setSource(source);
  }

  async renderKaraokeElements() {
    const config = this.karaokeConfig;
    if (!config) throw new Error('No karaoke config');
    const source = this.renderer!.getSource();
    // check if top track is free
    let track = KARAOKE_TRACK_NUMBER; //fix karaoke on top track
    console.log('config', config);
    source.elements = source.elements.filter((el: any) => el.track < track);

    let fontSize = parseFloat(config.font_size);
    let fontSizeUnits = config.font_size.match(/[a-z]+/)?.[0] || 'px';

    if (fontSizeUnits === 'px') {
      fontSize = convertFromPixels(fontSize, 'vh', {
        width: source.width,
        height: source.height,
      });
      fontSizeUnits = 'vh';
    }

    for (let i = 0; i < this.karaokeElements!.length; i++) {
      const element = this.karaokeElements![i];
      const duration =
        element.endTs - element.ts > 0 ? element.endTs - element.ts : 0.1; //MIN DURATION
      source.elements.push({
        id: element.id,

        track: track,
        time: element.ts,
        duration,
        type: 'text',
        text: this.sanitizedText(element.text, config),
        ...config,
        font_size: `${fontSize} ${fontSizeUnits}`,

        animations: config.animations.map((anim: any) => ({
          ...anim,
          duration: Math.max(0.1, duration - 0.2),
          fade: false,
          background_effect: 'animated',
        })),
      });
    }

    await this.renderer!.setSource(source);
  }
}
