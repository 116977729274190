import React, { useState } from 'react';
import { ElementState } from '../../renderer/ElementState';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { PropertyCaption } from './PropertyCaption';
import { PropertyNumericalInput } from './PropertyNumericalInput';
import styled from 'styled-components';
import { numericalInputStyles } from '../../styles/mainStyle';
import PropertyDropdown from '../common/PropertyDropdown';
import FadeProducer from '../../fadeEffectProcessor/FadeProducer';

interface GenericAnimationSettingsProps {
  activeElement: ElementState;
  manualKaraoke?: boolean;
}

export const GenericAnimationSettings: React.FC<
  GenericAnimationSettingsProps
> = (props) => {
  const fadeProducer = new FadeProducer(props.activeElement);
  const { animations, color_overlay } = props.activeElement.source;

  const enterAnimationData = animations?.find(
    (keyframe: any) => keyframe.time === 'start' || keyframe.time === 0,
  );
  const exitAnimationData = animations?.find(
    (keyframe: any) => keyframe.time === 'end',
  );

  const getEnterAnimation = () => {
    const fadeAnimationData = fadeProducer.getFadeVariables('enter');
    if (fadeAnimationData)
      return {
        animation: fadeAnimationData.type,
        duration: fadeAnimationData.duration,
      };

    const enterAnimation = enterAnimationData?.type ?? 'none';
    const enterAnimationDuration = enterAnimationData?.duration;
    return { animation: enterAnimation, duration: enterAnimationDuration };
  };

  const getExitAnimation = () => {
    const fadeAnimationData = fadeProducer.getFadeVariables('exit');
    if (fadeAnimationData)
      return {
        animation: fadeAnimationData.type,
        duration: fadeAnimationData.duration,
      };

    const exitAnimation = exitAnimationData?.type ?? 'none';
    const exitAnimationDuration = exitAnimationData?.duration;
    return { animation: exitAnimation, duration: exitAnimationDuration };
  };

  const enterAnimation = getEnterAnimation().animation;
  const exitAnimation = getExitAnimation().animation;

  const enterAnimationDuration = getEnterAnimation().duration;
  // const exitAnimationDuration = getExitAnimation().duration;

  const setAnimation = async (
    time: string | number | null,
    type: any,
    duration: number | null = null,
    background_effect: string | null = null,
  ) => {
    // Remove existing animation from list
    const newAnimations =
      animations?.filter((keyframe: any) => {
        const frameTime = keyframe.time === 0 ? 'start' : keyframe.time;
        return !(!frameTime && !time) && frameTime !== time;
      }) ?? [];

    let newOverlays = [...(color_overlay || [])];

    if (type !== 'none' && type !== 'dip' && type !== 'crossfade') {
      const animation: any = {
        type: type === 'dip' ? 'fade' : type,
      };

      if (!!time) {
        animation.time = time;
        animation.duration = duration || 1;
      }

      if (background_effect) {
        animation.background_effect = background_effect;
      }

      // Reverse animation when used as exit animation
      if (time === 'end') {
        animation.reversed = true;
      }

      if (time === 'start' && type === 'dip') {
        animation.scope = 'dip-entry';
        animation.reversed = true;
      }

      if (time === 'end' && type === 'dip') {
        animation.scope = 'dip-exit';
        animation.reversed = false;
      }

      newAnimations.push(animation);
    }

    const { newOverlays: elementOverlays, newVideoOverlaysDict } =
      fadeProducer.setOverlayAnimation(type, time, duration!, newOverlays);

    await videoCreator.renderer?.applyModifications({
      ...newVideoOverlaysDict,
      [`${props.activeElement.source.id}.color_overlay`]: elementOverlays.length
        ? elementOverlays
        : '',
      [`${props.activeElement.source.id}.animations`]: newAnimations,
    });
  };

  const getInputValue = async (time: string, duration: string) => {
    let type = time === 'start' ? enterAnimation : exitAnimation;
    let itemDuration = Number(duration);

    if (isNaN(itemDuration)) itemDuration = 1;
    await setAnimation(time, type, itemDuration);
  };

  return (
    <Content>
      <Item>
        <PropertyCaption>Enter Animation</PropertyCaption>
        <PropertyDropdown
          value={enterAnimation}
          onChange={async (value) => {
            await setAnimation('start', value, enterAnimationDuration);
          }}
          defaultValue={enterAnimation}
          values={[
            {
              caption: 'None',
              value: 'none',
            },
            ...Object.entries(GenericAnimationTypes).map(([type, caption]) => ({
              caption: type === 'dip' ? 'Dip from Black' : caption,
              value: type,
            })),
          ]}
        />
      </Item>

      <Item>
        <PropertyCaption>Enter Animation Length (s)</PropertyCaption>
        {/* todo: rework setting current value */}
        <PropertyNumericalInput
          activeElement={props.activeElement}
          propertyName="start"
          defaultValue={
            enterAnimation === 'dip' || enterAnimation === 'crossfade'
              ? getEnterAnimation().duration ?? 1
              : props.activeElement?.source?.animations?.find(
                  ({ time }: any) => time === 'start' || time === 0,
                )?.duration ?? '1'
          }
          unit="s"
          getInputValue={getInputValue}
          customStyles={numericalInputStyles}
        />
      </Item>

      <Item>
        <PropertyCaption>Exit Animation</PropertyCaption>

        <PropertyDropdown
          value={exitAnimation}
          onChange={async (value) => {
            await setAnimation('end', value, enterAnimationDuration);
          }}
          defaultValue={exitAnimation}
          values={[
            {
              caption: 'None',
              value: 'none',
            },
            ...Object.entries(GenericAnimationTypes).map(([type, caption]) => ({
              caption: type === 'dip' ? 'Dip to Black' : caption,
              value: type,
            })),
          ]}
        />
      </Item>
      <Item>
        <PropertyCaption>Exit Animation Length (s)</PropertyCaption>
        {/* todo: rework setting current value */}
        <PropertyNumericalInput
          activeElement={props.activeElement}
          propertyName="end"
          defaultValue={
            exitAnimation === 'dip' || exitAnimation === 'crossfade'
              ? getExitAnimation().duration ?? 1
              : props.activeElement?.source?.animations?.find(
                  ({ time }: any) => time === 'end',
                )?.duration ?? '1'
          }
          unit="s"
          getInputValue={getInputValue}
          customStyles={numericalInputStyles}
        />
      </Item>
    </Content>
  );
};

// Each of these animations has its own options
// For reference: https://github.com/Creatomate/creatomate-node/tree/main/src/animations
const FadeOverlayTypes = {
  dip: 'dip',
  crossfade: 'Cross fade',
};

const CommonAnimationTypes = {
  fade: 'Fade',
  scale: 'Scale',
  slide: 'Slide',
  // 'rotate-slide': 'Rotate Slide',
  pan: 'Pan',
  // wipe: 'Wipe',
  // 'color-wipe': 'Color Wipe',
  // 'circular-wipe': 'Circular Wipe',
  'film-roll': 'Film Roll',
  // squash: 'Squash',
  // spin: 'Spin',
  // stripe: 'Stripe',
  flip: 'Flip',
  // shake: 'Shake',
  // bounce: 'Bounce',
  // wiggle: 'Wiggle',
  shift: 'Shift',
};

const GenericAnimationTypes = {
  ...FadeOverlayTypes,
  ...CommonAnimationTypes,
};

const Section = styled.div``;
const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
`;

const Item = styled.div``;
