/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment } from 'react';
import { ElementState } from '../../renderer/ElementState';
import { VideoPreset } from './VideoPreset';
import { PropertyCaption } from './PropertyCaption';
import { PropertySelect } from './PropertySelect';
import { PropertyNumericalInput } from './PropertyNumericalInput';
import { videoCreator } from '../../stores/VideoCreatorStore';
import styled from 'styled-components';
import { ElementAnimationSettings } from './ElementAnimationSettings';
import { numericalInputStyles } from '../../styles/mainStyle';
import { GenericAnimationSettings } from './GenericAnimationSettings';
import { ExtraElementData } from '../../types.ts/story';
import FadeProducer from '../../fadeEffectProcessor/FadeProducer';
import { FIT_DEFAULT } from '../../config/videoSettings';

interface VideoSettingsProps {
  activeElement: ElementState;
}

export const VideoSettings: React.FC<VideoSettingsProps> = (props) => {
  const isBroll = !videoCreator.isOriginalVideoElement(props.activeElement.source)
  const fadeProducer = new FadeProducer(props.activeElement)

  const volumeKeyPoints = (
    videoCreator.currentVideo?.extraElementData[
    props.activeElement.source?.id
    ] as ExtraElementData | null
  )?.volumeKeyPoints;

  const setKeypointVolume = async (propertyName: string, value: any) => {
    if (volumeKeyPoints && volumeKeyPoints.length > 0) {
      let newVolumeKeyPoints = JSON.parse(JSON.stringify(volumeKeyPoints));

      if (propertyName === 'start') {
        volumeKeyPoints[0].value = value;
      } else {
        volumeKeyPoints[volumeKeyPoints.length - 1].value = value;
      }

      videoCreator.applyVolumeKeyPoints(
        props.activeElement.source.id,
        volumeKeyPoints,
      );
    }
  };

  const hasVolumeKeypoint = volumeKeyPoints && volumeKeyPoints?.length > 0
  return (
    <Fragment>
      <VideoPreset
        activeElement={props.activeElement}
        url={props.activeElement.source.source}
      />

      <ElementAnimationSettings activeElement={props.activeElement} />

      <GroupContent>
        <Item>
          <PropertyCaption>Start</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="time"
            defaultValue="auto"
            unit="s"
            customStyles={numericalInputStyles}
            getInputValue={async (property: string, value: string) => {
              const newVideoOverlays = await fadeProducer.resetCrossfadeOnVideo(
                parseFloat(value),
                props.activeElement.duration,
              )

              await videoCreator.renderer?.applyModifications({
                ...newVideoOverlays,
                [`${props.activeElement?.source.id}.${property}`]: value,
              });
            }}
          />
        </Item>

        <Item>
          <PropertyCaption>Duration</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="duration"
            defaultValue="auto"
            unit="s"
            customStyles={numericalInputStyles}
            getInputValue={async (property: string, value: string) => {
              const newVideoOverlays = await fadeProducer.resetCrossfadeOnVideo(
                props.activeElement.source.time,
                parseFloat(value)
              )
              await videoCreator.renderer?.applyModifications({
                ...newVideoOverlays,
                [`${props.activeElement?.source.id}.${property}`]: value,
              });
            }}
          />
        </Item>

        <Item>
          <PropertyCaption>Fit</PropertyCaption>
          <PropertySelect
            activeElement={props.activeElement}
            propertyName="fit"
            defaultValue={FIT_DEFAULT}
            options={[
              { caption: 'Cover', value: 'cover' },
              { caption: 'Contain', value: 'contain' },
              { caption: 'Fill', value: 'fill' },
            ]}
          />
        </Item>
        {isBroll && !hasVolumeKeypoint && <Item>
          <PropertyCaption>Volume</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="volume"
            defaultValue="100"
            unit="%"
            customStyles={numericalInputStyles}
          />
        </Item>}
      </GroupContent>

      {isBroll && hasVolumeKeypoint && <GroupContent>
        <Item>
          <PropertyCaption>Start Volume</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            getInputValue={setKeypointVolume}
            propertyName="start"
            defaultValue={volumeKeyPoints[0].value}
            unit="%"
            customStyles={numericalInputStyles}
          />
        </Item>

        <Item>
          <PropertyCaption>End Volume</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            getInputValue={setKeypointVolume}
            propertyName="end"
            defaultValue={volumeKeyPoints[volumeKeyPoints.length - 1].value}
            unit="%"
            customStyles={numericalInputStyles}
          />
        </Item>
      </GroupContent>

      }


      <GenericAnimationSettings activeElement={props.activeElement} />

      <GroupContent>
        <Item>
          <PropertyCaption>Audio Fade In</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="audio_fade_in"
            defaultValue="0"
            unit="s"
            customStyles={numericalInputStyles}
          />
        </Item>
        <Item>
          <PropertyCaption>Audio Fade Out</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="audio_fade_out"
            defaultValue="0"
            unit="s"
            customStyles={numericalInputStyles}
          />
        </Item>
      </GroupContent>
    </Fragment>
  );
};


const GroupContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
`

const Item = styled.div`
`
