import React, { useState } from 'react';
import styled from 'styled-components';
import PrimaryActionButton from './PrimaryActionButton';
import MusicIcon from '../../svgs/MusicIcon';
import type { Song } from '../../types.ts/story';

type ResultType = {
  handleSongSelect: (song: Song) => void;
  isSongSelected: (song: Song) => boolean;
};

type SongCollectionProps = {
  children: (r: ResultType) => React.ReactNode;
  onAddToTrack: (
    url: string,
    songName: string,
    mediaDuration: string,
  ) => Promise<void>;
};

const SongCollection = ({ children, onAddToTrack }: SongCollectionProps) => {
  const [song, setSong] = useState<Song | undefined>();

  const handleActionClick = () => {
    if (!song?.id) {
      return;
    }
    onAddToTrack(
      song.url,
      song.customData?.songName,
      song.customData?.duration,
    );
  };

  const handleSongSelect: ResultType['handleSongSelect'] = (newSong) => {
    if (song?.id === newSong.id) {
      setSong(undefined);
    } else {
      setSong(newSong);
    }
  };

  const isSongSelected: ResultType['isSongSelected'] = (songToCheck) =>
    song?.id === songToCheck.id;

  return (
    <>
      {children({ handleSongSelect, isSongSelected })}
      <StickyWrapper>
        <AddMusicButton
          type="button"
          onClick={handleActionClick}
          disabled={!song?.id}
        >
          <MusicIcon strokeColor="#03041A" />
          Add Music
        </AddMusicButton>
      </StickyWrapper>
    </>
  );
};

const AddMusicButton = styled(PrimaryActionButton)`
  height: 40px;
  font-size: 14px;
  svg {
    margin-right: 8px;
  }
`;

const StickyWrapper = styled.div`
  padding: 10px;
  position: sticky;
  bottom: 1px;
  box-shadow: 0px 1px 0 0 #030419;
  background: #030419;
  z-index: 2;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: -15px;
`;

export default SongCollection;
