import React, { useRef } from 'react';
import styled from 'styled-components';
import { ElementState } from '../../renderer/ElementState';
import { TimelineElement } from './TimelineElement';
import { Story } from '../../types.ts/story';
import TimelineContextMenu from './TimelineContextMenu';
import { KARAOKE_TRACK_NUMBER } from '../../stores/VideoCreatorStore';

interface TimelineTrackProps {
  elements: ElementState[];
  story?: Story;
  type?: string;
  track?: number;
  isActive: boolean;
  isOriginalVideo?: ConstrainBoolean;
  onContextMenu?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    el: ElementState,
  ) => void;
  handlePasteContextMenu?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    track: number,
    elements: ElementState[],
  ) => void;
  isLogo?: boolean;
  width?: number;
}

export const TimelineTrack: React.FC<TimelineTrackProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Main
      ref={ref}
      linkedVideo={
        props.elements[0].source.type === 'video' &&
        props.type !== 'linked-audio'
      }
      isOriginalVideo={!!props.isOriginalVideo}
      linkedAudio={
        props.elements[0].source.type === 'video' &&
        props.type === 'linked-audio'
      }
      isText={
        props.elements[0].source.type === 'text' ||
        props.elements[0].source.track === KARAOKE_TRACK_NUMBER
      }
      isActive={props.isActive}
      onContextMenu={(e) => {
        if (props.handlePasteContextMenu && props.track) {
          props.handlePasteContextMenu(e, props.track, props.elements);
        }
      }}
      isLogo={props.isLogo}
      style={{ width: props.width }}
    >
      <TimelineContextMenu action="paste" />

      {props.elements.map((element, index) => (
        <TimelineElement
          key={`element-${index}-${element.source.id}`}
          type={props.type}
          element={element}
          allElements={props.elements}
          story={props.story}
          onContextMenu={props.onContextMenu}
        />
      ))}
    </Main>
  );
};

const Main = styled.div<{
  linkedVideo: boolean;
  linkedAudio: boolean;
  isText: boolean;
  isActive: boolean;
  isOriginalVideo: boolean;
  isLogo?: boolean;
}>`
  position: relative;
  height: ${(props) => {
    if (props.isOriginalVideo) return '59px';
    if (props.linkedAudio) return '40px';
    if (props.isText || props.isLogo) return '38px';
    return '78px';
  }};
  // padding-left: 14px;
  padding-top: ${(props) => (props.linkedAudio ? '0px' : '4px')};
  box-sizing: border-box;
`;
