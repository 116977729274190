import React, { useState } from 'react';
import { Artifact, Story } from '../../types.ts/story';
import styled, { css } from 'styled-components';
import { videoCreator } from '../../stores/VideoCreatorStore';
import SpinningLoading from '../SpinningLoading';
import { observer } from 'mobx-react-lite';
import FileUpload from '../common/FileUpload';
import MediaCard from './MediaCard';
import EllipsisIcon from '../../svgs/EllipsisIcon';
import { MediaSubType } from '../../types.ts/general';
import PhotoIcon from '../../svgs/PhotoIcon';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import DownloadIcon from '../../svgs/DownloadIcon';
import { chunkArray, delay, handleDownloadMedia } from '../../utility/general';
import { ShareModalAnimate } from '../../styles/mainStyle';

type Media = {
  id: string;
  src: string;
  description?: string;
  subType: Omit<MediaSubType, MediaSubType.all>;
  playbackId?: string;
  poster?: string;
  type: 'video' | 'photo';
  createdAt: Date;
};

const MediaView = observer(() => {
  const story = videoCreator.story;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadButtonActive, setUploadButtonActive] = useState<boolean>(false);
  const [mediaOnDownloadStream, setMediaOnDownloadStream] = useState<string[]>(
    [],
  );

  const [activeMedia, setActiveMedia] = useState<MediaSubType>(
    MediaSubType.all,
  );
  let mediaContent: Media[] = [];

  const getMediaSource = (a: Artifact) => {
    if (a.responsiveImage) {
      return { src: a.responsiveImage.src, type: 'image' };
    }
    if (a.video || a.mimeType === 'video/mp4') {
      return {
        src: a.url,
        playbackId: a.video?.muxPlaybackId,
        poster: a.video?.thumbnailUrl,
        type: 'video',
      };
    }
    return null;
  };

  const getMedia = (media: Artifact[] | undefined, subType: MediaSubType) => {
    return (media
      ?.map((a) => {
        const { src, type, playbackId, poster } = getMediaSource(a) || {};
        if (src) {
          return {
            id: a.id,
            src,
            description: a.title,
            subType,
            type,
            playbackId,
            poster,
            createdAt: new Date(a._createdAt),
          };
        }
        return null;
      })
      .filter(Boolean) || []) as Media[];
  };

  const getAllMedia = (story: Story | undefined) => {
    const allMedia = [
      ...getMedia(story?.storyArtifacts, MediaSubType.artifact),
      ...getMedia(story?.storyAssets, MediaSubType.stock),
      ...getMedia(story?.aiPhotos, MediaSubType.ai),
      ...getMedia(story?.storyArtifactsVideo, MediaSubType.video),
    ];
    return allMedia.sort((a, b) => (a.createdAt as any) - (b.createdAt as any));
  };

  if (activeMedia === MediaSubType.artifact) {
    mediaContent = getMedia(story?.storyArtifacts, MediaSubType.artifact);
  } else if (activeMedia === MediaSubType.stock) {
    mediaContent = getMedia(story?.storyAssets, MediaSubType.stock);
  } else if (activeMedia === MediaSubType.ai) {
    mediaContent = getMedia(story?.aiPhotos, MediaSubType.ai);
  } else if (activeMedia === MediaSubType.video) {
    mediaContent = getMedia(story?.storyArtifactsVideo, MediaSubType.video);
  } else if (activeMedia === MediaSubType.all) {
    mediaContent = getAllMedia(story);
  }

  async function handleDownloadAllMedia(
    e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) {
    e.preventDefault();

    const files = mediaContent.map((media) => ({
      url: media.src,
      title: media?.description,
      id: media.id,
    }));
    const maxConcurrentDownloads = 5;
    const chunks = chunkArray(files, maxConcurrentDownloads);

    setMediaOnDownloadStream(mediaContent.map((m) => m.id));
    for (const chunk of chunks) {
      await Promise.all(chunk.map((file, i) => downloadMedia(e, file, i)));
      await delay(1000);
    }
  }

  async function downloadMedia(
    e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    _file: {
      url: string;
      title: string | undefined;
      id: string;
    },
    i: number,
  ) {
    const { url, title, id } = _file;
    try {
      if (!url) return;

      const file = { url, title, id };

      await handleDownloadMedia(e, file);
    } catch (error) {
      console.log(error);
    } finally {
      setMediaOnDownloadStream((prev) => prev.filter((m) => m !== id));
    }
  }
  const isDownloading = Boolean(mediaOnDownloadStream.length);
  return (
    <Main>
      <TopMenu>
        <Tabs>
          {Object.entries(MediaSubType).map(([k, v]) => (
            <TabButton
              onClick={() => setActiveMedia(v)}
              isSelected={activeMedia === v}
              key={k}
            >
              {v}
            </TabButton>
          ))}
        </Tabs>

        <LeftItems>
          <DownloadAllButton onClick={handleDownloadAllMedia}>
            {isDownloading ? (
              <Animate />
            ) : (
              <DownloadIcon strokeColor="#17C964" />
            )}

            <span>Download all</span>
          </DownloadAllButton>
          <FileUpload
            Button={
              <AddFileButton isActivated={uploadButtonActive}>
                <PhotoIcon strokeColor="#03041A" /> Upload Media
              </AddFileButton>
            }
            showToggle={false}
            onClick={() => {
              setUploadButtonActive(true);
              setTimeout(() => setUploadButtonActive(false), 3000);
            }}
            setIsLoading={setIsLoading}
          />
        </LeftItems>
      </TopMenu>

      <Content>
        {isLoading && (
          <SpinningLoading
            customStyle={{
              top: 0,
              position: 'fixed',
              alignItems: 'center',
            }}
            text="Saving new artifacts"
          />
        )}
        {mediaContent?.map((media) => (
          <MediaCard
            isInDownloadStream={mediaOnDownloadStream.includes(media.id)}
            media={media}
          />
        ))}
      </Content>
    </Main>
  );
});

export default MediaView;

const Main = styled.main``;

const TopMenu = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftItems = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Tabs = styled.nav`
  display: flex;
  gap: 10px;

  @media only screen and (max-width: 750px) {
    margin-top: 10px;
    width: 100%;
    overflow-x: scroll;
  }
`;

const TabButton = styled.button<{ isSelected?: boolean }>`
  border-radius: 4px;
  background-color: transparent;
  outline: 0;
  border: 1px solid #484848;
  color: #484848;
  cursor: pointer;
  padding: 8px;
  ${(props) =>
    props.isSelected &&
    css`
      border-color: #f2d093;
      background-color: #f2d093;
    `}
`;
const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
  @media only screen and (max-width: 750px) {
    justify-content: center;
  }
`;

const AddFileButton = styled.button<{ isActivated?: boolean }>`
  box-sizing: border-box;
  height: 40px;
  outline: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 16px;
  border-radius: 8px;
  width: 100%;
  background-color: #17c964;
  justify-content: center;
  font-weight: 600;
  flex: 1;
  cursor: pointer;
  opacity: ${(props) => (props.isActivated ? '1' : '0.9')};
`;

const DownloadAllButton = styled.button`
  background-color: transparent;
  outline: none;
  border: 1px solid #17c964;
  font-weight: 700;
  font-size: 14px;
  line-height: 16.94px;
  color: #17c964;
  font-family: 'Inter', sans-serif;
  border-radius: 8px;
  width: 144px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
`;

const Animate = styled(ShareModalAnimate)``;
